import { useHistory } from 'react-router-dom'
import PrimaryButton from 'components/UIComponents/PrimaryButton'

import styles from './NotAuthorized.module.scss'

function NotAuthorized() {
	const history = useHistory()

	return (
		<div className={styles.notAuthorized}>
			<div className={styles.card}>
				<div className={styles.notAuthorizedHeader}>You are not authorized for this seller</div>
				<div className={styles.notAuthorizedText}>
					If you believe you have reached this message in error please contact{' '}
					<a href='mailto:client_support@valenceintel.com'>client_support@valenceintel.com</a>
				</div>
				<div className={styles.homeButtonContainer}>
					<PrimaryButton onClick={() => history.push('/')}>Home</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default NotAuthorized
