import { useQueryClient } from '@tanstack/react-query'

export function useRefreshData() {
	const queryClient = useQueryClient()

	// refetch everything from the database
	async function refreshAllData() {
		await queryClient.invalidateQueries()
	}

	function clearAllDataCaches() {
		queryClient.removeQueries()
	}

	return {
		refreshAllData,
		clearAllDataCaches,
	}
}
