import { Link as CLink, LinkProps, ComponentWithAs } from '@chakra-ui/react'

const linkStyles: LinkProps = {
	textDecoration: 'none',
	transition: 'color 0.2s linear',
	color: 'teal.500',
	_visited: {
		color: 'teal.500',
	},
}
const Link: ComponentWithAs<'a', LinkProps> = (props: LinkProps) => {
	return (
		<CLink {...linkStyles} {...props}>
			{props.children}
		</CLink>
	)
}

export default Link
