import PrimaryButton from 'components/UIComponents/PrimaryButton'

import styles from './SelectorButtonGroup.module.scss'

function SelectorButtonGroup({ setStateCallback, isTrueSelection, yesLabel, noLabel }: any) {
	return (
		<div className={styles.buttonContainer}>
			<div className={styles.button}>
				<PrimaryButton
					colorScheme={isTrueSelection ? 'vOrange' : 'vBlue'}
					variant={isTrueSelection ? 'solid' : 'outline'}
					borderColor='#BBD0D3'
					onClick={() => setStateCallback(true)}
				>
					{yesLabel ? yesLabel : 'Yes'}
				</PrimaryButton>
			</div>
			<div className={styles.button}>
				<PrimaryButton
					colorScheme={isTrueSelection === false ? 'vOrange' : 'vBlue'}
					variant={isTrueSelection === false ? 'solid' : 'outline'}
					borderColor='#BBD0D3'
					onClick={() => setStateCallback(false)}
				>
					{noLabel ? noLabel : 'No'}
				</PrimaryButton>
			</div>
		</div>
	)
}

export default SelectorButtonGroup
