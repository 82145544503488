import { createContext, useState } from 'react'

type ContextProps = {
	currentMarketplaceCurrency: string
	setCurrentMarketplaceCurrency: (update: string) => void
}
export const CurrentFeeMarketplaceContext = createContext({} as ContextProps)

type props = {
	children: JSX.Element | JSX.Element[]
}

export const CurrentFeeMarketplaceProvider = ({ children }: props) => {
	const [currentMarketplaceCurrency, setCurrentMarketplaceCurrency] = useState('USD')

	return (
		<CurrentFeeMarketplaceContext.Provider value={{ currentMarketplaceCurrency, setCurrentMarketplaceCurrency }}>
			{children}
		</CurrentFeeMarketplaceContext.Provider>
	)
}

export const { Consumer: CurrentFeeMarketplaceConsumer } = CurrentFeeMarketplaceContext
