import { Controller, Control } from 'react-hook-form'

import { Input, InputGroup, InputLeftElement, FormErrorMessage, FormLabel, FormControl, HStack } from '@chakra-ui/react'
import InfoTooltip from 'components/InfoTooltip'

type FormInputProps = {
	name: string
	defaultValue?: string
	label: string
	rules?: any
	control: Control<any>
	Icon?: React.ReactNode
	helperText: any
	error: boolean
	type?: string
	toolTip?: string
	placeholder?: string
	fontSize?: string
	readOnly?: boolean
}

const FormInput = ({
	name,
	defaultValue = '',
	label,
	type = 'text',
	rules,
	Icon,
	control,
	error,
	helperText,
	toolTip,
	placeholder,
	fontSize,
	readOnly = false,
}: FormInputProps) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field }) => (
				<FormControl isInvalid={error}>
					<FormLabel>
						<HStack>
							<span>{label}</span> {toolTip ? <InfoTooltip text={toolTip} /> : null}
						</HStack>
					</FormLabel>
					{Icon ? (
						<InputGroup>
							<InputLeftElement>{Icon}</InputLeftElement>
							<Input
								{...field}
								type={type}
								bgColor='white'
								fontSize={fontSize}
								placeholder={placeholder}
								isReadOnly={readOnly}
							/>
						</InputGroup>
					) : (
						<Input {...field} type={type} isReadOnly={readOnly} />
					)}
					<FormErrorMessage>{helperText}</FormErrorMessage>
				</FormControl>
			)}
		/>
	)
}

export default FormInput
