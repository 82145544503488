import { ListItem, UnorderedList, VStack, Text } from '@chakra-ui/react'

export enum CarrierTypes {
	login,
	createUser,
	other,
}

export type Carrier = {
	name: string
	type: CarrierTypes
	url?: string
	instructions?: () => React.ReactNode
}

export const carriers: Record<string, Carrier> = {
	AAA_Cooper: {
		name: 'AAA Cooper',
		type: CarrierTypes.login,
		url: 'https://www.aaacooper.com/pwb/Workspace/',
	},
	ABF_Freight_ArcBest: {
		name: 'ABF Freight / ArcBest',
		type: CarrierTypes.login,
		url: 'https://arcb.com/registration/customer#/?refId=adblock',
	},
	FedEx_Freight: {
		name: 'FedEx Freight',
		type: CarrierTypes.login,
		url: 'https://www.fedex.com/register/contact',
		instructions: () => (
			<VStack>
				<Text textDecor='underline'>If you don't already have an account</Text>
				<UnorderedList>
					<ListItem>Click the "+ Add More Accounts" button</ListItem>
					<ListItem>Select the appropriate Shipping Profile and Account Type</ListItem>
					<ListItem>Set the Account Type to "3rd Party FedEx"</ListItem>
					<ListItem>Click "Save"</ListItem>
					<ListItem>Enter your FedEx account number, drop-off type, and address validation details</ListItem>
					<ListItem>Click "Save" again</ListItem>
					<ListItem>A confirmation banner at the top of the screen should display "Account FedEx saved!"</ListItem>
				</UnorderedList>
			</VStack>
		),
	},
	Old_Dominion: {
		name: 'Old Dominion',
		type: CarrierTypes.login,
		url: 'https://www.odfl.com/',
	},
	PILOT_Air_Freight: {
		name: 'PILOT Air Freight',
		type: CarrierTypes.login,
		url: 'https://www.pilotfreight.com/',
	},
	Saia: {
		name: 'Saia',
		type: CarrierTypes.login,
		url: 'https://www.saia.com/home',
	},
	UPS: {
		name: 'UPS',
		type: CarrierTypes.login,
		url: 'https://www.ups.com/us/en/Home.page',
	},
	XPO: {
		name: 'XPO',
		type: CarrierTypes.login,
		url: 'https://ext-web.ltl-xpo.com/landing',
	},
	AmazonFreight: {
		name: 'Amazon Freight',
		type: CarrierTypes.createUser,
		url: 'https://freight.amazon.com/',
		instructions: () => (
			<UnorderedList>
				<ListItem>Sign in to your Amazon Freight account</ListItem>
				<ListItem>Navigate to the "Get Started" section</ListItem>
				<ListItem>Proceed to the "Company Profile Setup" page</ListItem>
				<ListItem>Access the "Setup" menu</ListItem>
				<ListItem>Select the "Users" option</ListItem>
				<ListItem>Click "Add User" and enter the Valence email address</ListItem>
			</UnorderedList>
		),
	},
	TForce: {
		name: 'TForce',
		type: CarrierTypes.createUser,
		url: 'https://www.tforcefreight.com/ltl/',
	},
	Other: {
		name: 'Other',
		type: CarrierTypes.other,
	},
}
