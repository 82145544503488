import { useState } from 'react'
import { carriers as carriersDetails, CarrierTypes } from '../carriers'
import { Progress, VStack, Text } from '@chakra-ui/react'
import CarrierCreateAccountForm from './CarrierCreateAccountForm'
import CarrierLoginForm from './CarrierLoginForm'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import CarrierOtherForm from './CarrierOtherForm/CarrierOtherForm'

type CarrierCredentialsFormProps = {
	carriers: string[]
	companyName: string
	email: string
}

function CarrierCredentialsForm({ carriers, companyName, email }: CarrierCredentialsFormProps) {
	const [currentCarrierIndex, setCurrentCarrierIndex] = useState<number>(0)
	const [progress, setProgress] = useState(0)
	const [finished, setFinished] = useState(false)

	const currentCarrierAccessor = carriers[currentCarrierIndex]
	const currentCarrier = currentCarrierAccessor ? carriersDetails[currentCarrierAccessor] : null

	function renderForm() {
		switch (currentCarrier?.type) {
			case CarrierTypes.login:
				return (
					<CarrierLoginForm
						onSubmitCallback={handleSubmitCallback}
						carrier={currentCarrier}
						companyName={companyName}
						email={email}
					/>
				)

			case CarrierTypes.createUser:
				return (
					<CarrierCreateAccountForm
						companyName={companyName}
						email={email}
						onSubmitCallback={handleSubmitCallback}
						carrier={currentCarrier}
					/>
				)
			default:
				// This is also where the "other" option is sent
				return <CarrierOtherForm onSubmitCallback={handleSubmitCallback} companyName={companyName} email={email} />
		}
	}

	function handleSubmitCallback() {
		setProgress((prevProgress) => prevProgress + 100 / carriers.length)

		if (currentCarrierIndex === carriers.length - 1) {
			setFinished(true)
		} else {
			setCurrentCarrierIndex((prevIndex) => prevIndex + 1)
		}
	}

	if (finished) {
		return (
			<VStack>
				<Text>Completed, thank you!</Text>
				<Text>
					You may close this tab, or go to our{' '}
					<ChakraLink as={ReactRouterLink} to='/' color='blue.500'>
						Dashboard
					</ChakraLink>
				</Text>
			</VStack>
		)
	}

	return (
		<VStack width='100%'>
			<Progress value={progress} mb={4} mx={4} width='100%'>
				{' '}
			</Progress>
			{renderForm()}
		</VStack>
	)
}

export default CarrierCredentialsForm
