import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from 'api'
import Loader from 'components/Loader'
import Logo from 'images/ValenceLogoLight.png'

import NotAuthorized from './NotAuthorized'
import Completed from './Completed'
import styles from './Questionnaire.module.scss'
import QuestionnaireForm from 'components/QuestionnaireForm'

function Questionnaire() {
	const { identifier } = useParams<any>()

	const [authorized, setAuthorized] = useState(true)
	const [loading, setLoading] = useState(false)
	const [defaults, setDefaults] = useState<any>({})

	const [cognitoSub, setCognitoSub] = useState<string | undefined>()
	const [sellingPartnerId, setSellingPartnerId] = useState<string | undefined>()
	const [hasCompletedSurvey, setHasCompletedSurvey] = useState(false)

	useEffect(() => {
		const [cognitoSub, sellingPartnerId] = identifier.split('+')

		const validatePermissions = async () => {
			setLoading(true)
			try {
				const { data: defaults } = await api.get('/questionnaire/authorized', {
					params: {
						cognitoSub,
						sellingPartnerId,
					},
				})

				if (defaults.SurveyCompleted) {
					setHasCompletedSurvey(true)
				}

				setAuthorized(true)
				setDefaults(defaults)
			} catch (e) {
				setAuthorized(false)
			} finally {
				setLoading(false)
			}
		}

		setCognitoSub(cognitoSub)
		setSellingPartnerId(sellingPartnerId)
		void validatePermissions()
	}, [identifier])

	if (loading) {
		return (
			<div className={styles.loading}>
				<Loader />
			</div>
		)
	}

	if (!authorized) {
		return <NotAuthorized />
	}

	if (hasCompletedSurvey) {
		return <Completed />
	}

	return (
		<div className={styles.container}>
			<div className={styles.card}>
				<div className={styles.logo}>
					<img src={Logo} alt='logo' />
				</div>
				<QuestionnaireForm
					cognitoSub={cognitoSub}
					sellingPartnerId={sellingPartnerId}
					defaults={{
						defaultIsManufacture: defaults.IsManufacturer,
						defaultAutoBill: defaults.AutoBill,
						defaultAccessToBoLs: defaults.AccessToBoLs,
						defaultInboundAuditDollarMinimum: defaults.InboundAuditDollarMinimum,
						defaultDocumentationContactEmail: defaults.DocumentationContactEmail,
						defaultBillingContactEmail: defaults.BillingContactEmail,
						defaultHandlingInboundAudits: !!defaults.AuditClass1,
					}}
					onSubmit={() => setHasCompletedSurvey(true)}
				/>
			</div>
		</div>
	)
}

export default Questionnaire
