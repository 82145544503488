import { Box, Heading, Text, Link, VStack, Image } from '@chakra-ui/react'
import Logo from 'images/ValenceLogoLight.png'

function Maintenance() {
	return (
		<Box height='100vh' padding={10}>
			<VStack width='650px' margin='0 auto'>
				<Image src={Logo} alt='logo' mb='23px' height='80px' />
				<Heading as='h1' fontSize='50px' mb={10}>
					We will be back soon!
				</Heading>
				<VStack>
					<Text>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.</Text>
					<Text>
						If you need to you can always contact us at{' '}
						<Link
							href='mailto:client_support@valenceintel.com'
							color='#149BD7'
							textDecoration='none'
							_hover={{ color: '#333', textDecoration: 'none' }}
						>
							client_support@valenceintel.com
						</Link>
						, otherwise we will be back online shortly!
					</Text>

					<Text>&mdash; Valence Intelligence</Text>
				</VStack>
			</VStack>
		</Box>
	)
}

export default Maintenance
