import {
	Box,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Switch,
	VStack,
	Text,
	Textarea,
} from '@chakra-ui/react'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import _ from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSaveCarrier } from '../mutations'

type CarrierOtherFormProps = {
	onSubmitCallback: (data: { username: string; password: string }) => void
	companyName: string
	email: string
}

function CarrierOtherForm({ onSubmitCallback, companyName, email }: CarrierOtherFormProps) {
	const [showPassword, setShowPassword] = useState(false)
	const { mutateAsync: saveCarrier } = useSaveCarrier()
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			username: '',
			password: '',
			carrierName: '',
			notes: '',
		},
	})

	const customEmail = `${_.snakeCase(companyName)}@valenceintel.com`

	const onSubmit = async (data: any) => {
		await saveCarrier({
			username: data.username || customEmail,
			companyName: companyName,
			email: email,
			carrierName: data.carrierName,
			password: data.password,
			notes: data.notes,
		})
		reset()
		onSubmitCallback(data)
	}

	return (
		<Box as='form' onSubmit={handleSubmit(onSubmit)}>
			<VStack>
				<VStack gap={0} mb={5}>
					<Box>
						<Text>
							Please enter information for the carrier below. If you need to invite an email, please invite{' '}
							{customEmail} and tell us in the notes.
						</Text>
					</Box>
				</VStack>

				<FormControl isInvalid={!!errors.carrierName}>
					<FormLabel htmlFor='username'>Carrier Name</FormLabel>
					<Input
						id='carrierName'
						placeholder='Carrier Name'
						{...register('carrierName', { required: 'Carrier name is required' })}
					/>
					<FormErrorMessage>{errors.carrierName && errors.carrierName.message}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={!!errors.username}>
					<FormLabel htmlFor='username'>Username</FormLabel>
					<Input id='username' placeholder='Username' {...register('username')} />
					<FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={!!errors.password} mb={4}>
					<FormLabel htmlFor='password'>Password</FormLabel>
					<Input
						id='password'
						type={showPassword ? 'text' : 'password'}
						placeholder='Password'
						{...register('password')}
					/>

					<Flex alignItems='center' mt='3' width='100%' justify='flex-end'>
						<Switch size='sm' isChecked={showPassword} onChange={() => setShowPassword((prev) => !prev)} />
						<Text fontSize='sm' marginLeft='10px'>
							{showPassword ? 'Hide' : 'Show'}
						</Text>
					</Flex>
					<FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={!!errors.notes}>
					<FormLabel htmlFor='notes'>Notes</FormLabel>
					<Textarea id='notes' placeholder='Notes' {...register('notes')} />
					<FormErrorMessage>{errors.notes && errors.notes.message}</FormErrorMessage>
				</FormControl>

				<Flex width='100%' justify='flex-end'>
					<PrimaryButton mt={4} type='submit'>
						Next
					</PrimaryButton>
				</Flex>
			</VStack>
		</Box>
	)
}

export default CarrierOtherForm
