import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { fetcher } from 'api/fetchers'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import moment from 'moment'
import { openSCLinkAccount } from 'utils'
import { useUser } from './user'
import { theme } from 'styles/theme'

export function useSellerData() {
	const queryKey = ['/sellers']
	const { isLoading, error, data } = useQuery({
		queryKey: queryKey,
		queryFn: () => fetcher('/sellers'),
	})
	const { user: currentUser } = useUser()
	const queryClient = useQueryClient()

	if (error instanceof Error) {
		if ((error as Error).message !== 'accesstoken is undefined') {
			console.log(error)
			toast.error('Error fetching user data')
		}
	}

	const isEnterprise = useMemo(() => {
		const enterprise = data?.find((seller: any) => !_.isNil(seller.EnterpriseSellerId))
		return !!enterprise
	}, [data])

	async function mutate(updateFunction: any) {
		await queryClient.setQueryData(queryKey, updateFunction)
	}

	async function revalidate() {
		await queryClient.invalidateQueries({
			exact: true,
			queryKey: ['/sellers'],
			refetchType: 'all',
		})
	}

	useEffect(() => {
		const inactiveSellers = data?.filter((seller: any) => seller?.Active === 0)
		if (!data || !currentUser?.userId) return
		const sellersWithLostAccess = data?.filter((seller: any) => seller?.EnterpriseSeller?.LostScAccess)
		if (sellersWithLostAccess.length > 0) {
			void Swal.fire({
				icon: 'error',
				title: 'Error',
				// For some reason Swal doesn't respect the UL styling to use bullets, so we have to do it ourselves
				// I added the list style of none, in case it's fixed in the future, so that it doesn't have double bullets
				html: `<div>We have lost access to submit audits for the following accounts:</div> <br/> 
				<ul style="list-style-type:none;">
					${sellersWithLostAccess.map((seller: any) => `<li>• ${seller.Name} </li>`)}
				</ul> <br/>
				<div>Please contact <a href='mailto:client_support@valenceintel.com' style="text-decoration:underline;">client_support@valenceintel.com</a> to resume audit submissions.</div>`,
			})
		}

		// Store the sellers as part of the key, so that they get notified again if they have a different set of inactive sellers
		const localStorageKey = 'inactiveSellerAlertDismissed-' + inactiveSellers.map((seller: any) => seller.Id).join(',')
		const localStorageDismissed = localStorage.getItem(localStorageKey)

		if (
			inactiveSellers.length > 0 &&
			(!localStorageDismissed || moment().diff(moment(localStorageDismissed), 'days') > 7) &&
			!data.some((seller: any) => seller.Id === 11)
		) {
			void Swal.fire({
				icon: 'error',
				title: 'Error',
				html: `<div>The following accounts are not active, and we are not pulling data for them:</div> <br/> 
						<ul style="list-style-type:none;">
						${inactiveSellers.map((seller: any) => `<li>• ${seller.Name} </li>`)}
						</ul> <br/>
						<div>To resolve the issue, please relink your Seller Central Account below.</div>
						<br/>
						<div>If this issue persists, Amazon may be preventing us from pulling your data. Please contact <a href='mailto:client_support@valenceintel.com' style="text-decoration:underline;">client_support@valenceintel.com</a> if this reoccurs.</div>`,
				showConfirmButton: true,
				confirmButtonText: 'Relink Account',
				confirmButtonColor: theme.colors.vBlue['600'],
				showCancelButton: true,
				cancelButtonText: 'Snooze',
			}).then((result) => {
				if (result.dismiss === Swal.DismissReason.cancel) {
					localStorage.setItem(localStorageKey, moment().toISOString())
				}
				if (result.isConfirmed && currentUser?.userId) {
					openSCLinkAccount(currentUser.userId)
				}
			})
		}
	}, [currentUser?.userId, data])

	return {
		data,
		error,
		isLoading,
		mutate,
		isEnterprise,
		revalidate,
		queryKey,
	}
}
