import { useForm } from 'react-hook-form'
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Flex,
	Switch,
	Text,
	VStack,
	Link,
} from '@chakra-ui/react'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import { useState } from 'react'
import { Carrier } from 'views/CarrierCredentials/carriers'
import { useSaveCarrier } from '../mutations'

type CarrierLoginFormProps = {
	onSubmitCallback: () => void
	carrier: Carrier
	companyName: string
	email: string
}

function CarrierLoginForm({ onSubmitCallback, carrier, companyName, email }: CarrierLoginFormProps) {
	const [showPassword, setShowPassword] = useState(false)
	const { mutateAsync: saveCarrier } = useSaveCarrier()
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
	})

	const onSubmit = async (data: { username: string; password: string }) => {
		await saveCarrier({
			username: data.username,
			password: data.password,
			carrierName: carrier.name,
			companyName: companyName,
			email: email,
		})
		reset()
		onSubmitCallback()
	}

	return (
		<Box as='form' onSubmit={handleSubmit(onSubmit)}>
			<VStack>
				<VStack gap={0} mb={5}>
					<Box>
						<Text>
							We are not able to create a secondary user for{' '}
							<Link href={carrier.url} isExternal textDecoration='underline'>
								{carrier.name}
							</Link>
							, so we will need access to your account in order to get shipping information.
						</Text>
					</Box>
				</VStack>

				<Box>{carrier.instructions && carrier.instructions()}</Box>

				<FormControl isInvalid={!!errors.username}>
					<FormLabel htmlFor='username'>Username</FormLabel>
					<Input id='username' placeholder='Username' {...register('username', { required: 'Username is required' })} />
					<FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={!!errors.password} mb={4}>
					<FormLabel htmlFor='password'>Password</FormLabel>
					<Input
						id='password'
						type={showPassword ? 'text' : 'password'}
						placeholder='Password'
						{...register('password', { required: 'Password is required' })}
					/>

					<Flex alignItems='center' mt='3' width='100%' justify='flex-end'>
						<Switch size='sm' isChecked={showPassword} onChange={() => setShowPassword((prev) => !prev)} />
						<Text fontSize='sm' marginLeft='10px'>
							{showPassword ? 'Hide' : 'Show'}
						</Text>
					</Flex>
					<FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
				</FormControl>

				<Flex width='100%' justify='flex-end'>
					<PrimaryButton mt={4} type='submit'>
						Next
					</PrimaryButton>
				</Flex>
			</VStack>
		</Box>
	)
}

export default CarrierLoginForm
