import moment from 'moment'

const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validateEmail(email: string) {
	if (email?.trim().length < 1) return 'Email Required'
	if (!emailValidation.test(email)) return 'Invalid Email'
	return true
}

export function validatePassword(password: string) {
	if (password.trim().length < 1) return 'Password Required'
	if (password.trim().length < 8) return 'Password minimum 8 characters'
	if (password.trim().length > 50) return 'Password maximum 50 characters'
	if (!/[A-Z]/.test(password)) return 'Password must have an uppercase letter'
	if (!specialChars.test(password)) return 'Password must have a special character'
	return true
}

export function checkPasswordMatch(password: string, confirmPassword: string) {
	if (password !== confirmPassword) return 'Does not match password'
	return true
}

export function validateRequired(s: string) {
	if (s.trim().length < 1) return 'Required'
	return true
}

export function validateCaseId(s: string) {
	const caseIdRegEx = /^[0-9]+$/

	if (!caseIdRegEx.test(s)) {
		//the caseid is invalid
		return 'Invalid Case Id'
	}
	return true
}

export function validateName(name: string) {
	if (name.trim().length < 1) return 'Name Required'
	return true
}

export function splitCardExpirationDate(date: string) {
	if (date.includes('/')) date = date.replace('/', '')
	const month = date.substr(0, 2)
	const year = date.substr(2)
	const data = { exp_month: month, exp_year: year }
	return data
}

export function validateExpiration(date: string) {
	if (date.trim().length < 6) return 'Invalid Expiration Date'
	const exp = splitCardExpirationDate(date)
	const month = parseInt(exp.exp_month)
	const year = parseInt(exp.exp_year)
	const momentDate = moment({ year, month })
	if (!momentDate.isValid() || momentDate.isBefore(moment())) return 'Invalid Expiration Date'
	return true
}

export function validateAddress(data: string) {
	if (data.trim().length < 1) return 'Required'
	return true
}

export function validatePhone(data: string) {
	if (!data || data?.length === 0) return true
	const exp = `^(\\+[0-9]{1,2}\\s?)?([(]?[0-9]{3}[)]?)[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$`
	const phoneRegex = new RegExp(exp)
	return phoneRegex.test(data) ? true : 'Invalid Phone Number'
}

export function validateDollarTotalIsNumber(data: string) {
	const cleanedValue = data.replace('$', '')
	if (!cleanedValue || cleanedValue?.length === 0) return true
	return !isNaN(Number(cleanedValue)) ? true : 'Must be a number'
}
