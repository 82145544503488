import { Controller, Control } from 'react-hook-form'
import { LockIcon } from '@chakra-ui/icons'
import {
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	FormErrorMessage,
	FormLabel,
	FormControl,
	HStack,
	Text,
} from '@chakra-ui/react'

type PasswordInputProps = {
	name: string
	defaultValue?: string
	label: string
	rules: any
	control: Control<any>
	helperText: any
	error: boolean
	showPassword?: boolean
}

const PasswordInput = ({
	name,
	defaultValue = '',
	label,
	rules,
	control,
	error,
	helperText,
	showPassword,
}: PasswordInputProps) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field }) => (
				<FormControl isInvalid={error}>
					<FormLabel>
						<HStack>
							<Text>{label}</Text>
						</HStack>
					</FormLabel>
					<InputGroup>
						<InputLeftElement>
							<LockIcon color='gray.400' fontSize='22px' />
						</InputLeftElement>
						<Input
							{...field}
							type={showPassword ? 'text' : 'password'}
							placeholder={'Enter Password'}
							fontSize='14px'
						/>
						<InputRightElement width='4.5rem'></InputRightElement>
					</InputGroup>
					<FormErrorMessage>{helperText}</FormErrorMessage>
				</FormControl>
			)}
		/>
	)
}

export default PasswordInput
