import { useSellerData } from 'hooks/sellers'
import _ from 'lodash'
import { createContext, useEffect, useState } from 'react'

type ContextProps = {
	currentSeller: any
	setCurrentSeller: (seller: any) => void
	updateCurrentEnterpriseSeller: (update: any) => void
	updateCurrentSellerBusinessDetails: (update: any) => void
}
export const CurrentSellerContext = createContext({} as ContextProps)

type props = {
	children: JSX.Element | JSX.Element[]
}
export const CurrentSellerContextProvider = ({ children }: props) => {
	const { data: sellers, mutate: allSellersMutate } = useSellerData()
	const [currentSeller, setCurrentSeller] = useState<any>(null)
	useEffect(() => {
		if (!sellers) return
		if (currentSeller) {
			const foundSeller = sellers.find((seller: any) => seller.Id === currentSeller.Id)
			if (foundSeller) {
				setCurrentSeller(foundSeller)
				return
			}
		}
		setCurrentSeller(_.first(sellers))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sellers])

	async function updateCurrentEnterpriseSeller(update: any) {
		setCurrentSeller((old: any) => {
			return {
				...old,
				EnterpriseSeller: {
					...old.EnterpriseSeller,
					...update,
				},
			}
		})

		await allSellersMutate((old: any) => {
			return old.map((seller: any) => {
				if (seller.SellerId === update.SellerId) {
					return {
						...seller,
						EnterpriseSeller: {
							...seller.EnterpriseSeller,
							...update,
						},
					}
				}
				return seller
			})
		})
	}

	async function updateCurrentSellerBusinessDetails(update: any) {
		setCurrentSeller((old: any) => {
			return {
				...old,
				SellerBusinessDetail: {
					...old.SellerBusinessDetail,
					...update,
				},
			}
		})

		await allSellersMutate((old: any) => {
			return old.map((seller: any) => {
				if (seller.SellerId === update.SellerId) {
					return {
						...seller,
						SellerBusinessDetail: {
							...seller.SellerBusinessDetail,
							...update,
						},
					}
				}
				return seller
			})
		})
	}

	const currentSellerContext = {
		setCurrentSeller,
		currentSeller,
		updateCurrentEnterpriseSeller,
		updateCurrentSellerBusinessDetails,
	}
	return <CurrentSellerContext.Provider value={currentSellerContext}>{children}</CurrentSellerContext.Provider>
}

export const { Consumer: UserContextConsumer } = CurrentSellerContext
