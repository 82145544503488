import { useMutation } from '@tanstack/react-query'
import api from 'api'
import toast from 'react-hot-toast'

type SaveCarrierArgs = {
	username?: string
	password?: string
	carrierName: string
	companyName: string
	email: string
	notes?: string
}

export const useSaveCarrier = () => {
	return useMutation({
		mutationFn: (data: SaveCarrierArgs) => {
			return api.post('/sellers/carrier_logins/credentials', {
				username: data.username,
				password: data.password,
				carrierName: data.carrierName,
				companyName: data.companyName,
				email: data.email,
				notes: data.notes,
			})
		},
		onError: (e: any) => {
			console.log(e)
			toast.error('There was an error. Your carrier information was not able to be saved')
		},
	})
}
