import { Box, Flex, Link, VStack, Text, Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { Carrier } from 'views/CarrierCredentials/carriers'
import { useSaveCarrier } from '../mutations'

type CarrierCreateAccountFormProps = {
	onSubmitCallback: (data: { username: string; password: string }) => void
	carrier: Carrier
	companyName: string
	email: string
}
function CarrierCreateAccountForm({ onSubmitCallback, carrier, companyName, email }: CarrierCreateAccountFormProps) {
	const { mutateAsync: saveCarrier } = useSaveCarrier()
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm()

	const customEmail = `${_.snakeCase(companyName)}@vintelaudit.com`

	const onSubmit = async (data: any) => {
		await saveCarrier({
			username: customEmail,
			carrierName: carrier.name,
			companyName: companyName,
			email: email,
		})
		reset()
		onSubmitCallback(data)
	}

	return (
		<Box as='form' onSubmit={handleSubmit(onSubmit)}>
			<VStack>
				<VStack gap={3} mb={5}>
					<Text>
						We are able to create a secondary user for{' '}
						<Link href={carrier.url} isExternal textDecoration='underline'>
							{carrier.name}
						</Link>
					</Text>

					<Text>The email we would like you to add as a secondary user is {customEmail}</Text>
				</VStack>

				{carrier.instructions && <Box>{carrier.instructions()}</Box>}

				<FormControl id='completed' isInvalid={!!errors.completed} mt={5}>
					<Checkbox {...register('completed', { required: true })}>I have added the user to my account</Checkbox>
					<FormErrorMessage>This field is required</FormErrorMessage>
				</FormControl>

				<Flex width='100%' justify='flex-end'>
					<PrimaryButton mt={4} type='submit'>
						Next
					</PrimaryButton>
				</Flex>
			</VStack>
		</Box>
	)
}

export default CarrierCreateAccountForm
