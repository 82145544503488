import { Button, ButtonProps } from '@chakra-ui/react'

const PrimaryButton = ({ children, ...props }: ButtonProps) => {
	return (
		<Button colorScheme={props.colorScheme ?? 'vOrange'} color={props.color ?? 'rgba(0, 0, 0, 0.87)'} {...props}>
			{children}
		</Button>
	)
}

export default PrimaryButton
