import { Center, Flex, Text, Icon, VStack } from '@chakra-ui/react'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'

import { useQueryParams } from 'hooks'
import { useEffect, useState } from 'react'
import SecondaryButton from 'components/UIComponents/SecondaryButton'
import api from 'api'
import Swal from 'sweetalert2'

function CheckEmail() {
	const query = useQueryParams()
	const [timer, setTimer] = useState(30)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (!loading) return
		const interval = setInterval(() => {
			if (timer > 0) setTimer(timer - 1)
		}, 1000)
		if (loading) {
			if (timer === 0) {
				setLoading(false)
				setTimer(30)
			}
		}
		return () => clearInterval(interval)
	}, [loading, timer])

	async function resendConfirmation() {
		setLoading(true)
		try {
			await api.post('/signup/resend_confirmation', {
				email: query.get('email'),
			})
			void Swal.fire({
				icon: 'success',
				title: 'Sent!',
				text: `Confirmation email resent to ${query.get('email')}.  Please be sure to check your spam folder as well`,
			})
		} catch (e: any) {
			void Swal.fire({
				icon: 'error',
				title: 'Error!',
				text: e?.response?.data,
			})
			setLoading(false)
		}
	}

	return (
		<Flex width='100%' height='100vh' justify='center' align='center'>
			<Flex
				maxW='md'
				minW='sm'
				minH='md'
				borderWidth='1px'
				borderRadius='lg'
				bg='white'
				direction='column'
				padding='40px'
				justify='space-between'
			>
				<Center>
					<Icon as={MdOutlineMarkEmailUnread} color='vBlue.600' fontSize='7xl' />
				</Center>
				<Text fontWeight='bold' align='center' fontSize='3xl'>
					Almost done!
				</Text>

				<Text align='center'>Please check {query.get('email')} to confirm your email address</Text>

				<VStack align='center'>
					<span>If you don't receive an email, click here</span>
					<SecondaryButton onClick={resendConfirmation} isDisabled={loading}>
						{loading ? `Can resend in ${timer}` : 'Resend Email'}
					</SecondaryButton>
				</VStack>

				<Text align='center'>If you are still having issues please contact client_support@valenceintel.com</Text>
			</Flex>
		</Flex>
	)
}

export default CheckEmail
