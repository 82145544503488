import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { Box, BoxProps, Button, Flex, Image, Tooltip, Switch, FormLabel } from '@chakra-ui/react'
import { EmailIcon, InfoIcon } from '@chakra-ui/icons'
import { MdConfirmationNumber } from 'react-icons/md'

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

import FormInput from 'components/FormInput'
import PasswordInput from 'components/PasswordInput'
import Logo from 'images/ValenceLogoLight.png'

import { validateEmail, validatePassword, checkPasswordMatch } from 'utils/formValidation'
import { confirmResetPassword } from 'aws-amplify/auth'

type passwordResetSubmission = {
	email: string
	confirmationCode: string
	newPassword: string
	confirmNewPassword: string
}

const PasswordReset = () => {
	const [loading, setLoading] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const history = useHistory()

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue,
	} = useForm<passwordResetSubmission>()

	const newPassword = watch('newPassword', '')
	const confirmNewPassword = watch('confirmNewPassword', '')

	const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword)
	const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

	useEffect(() => {
		const storedEmail = localStorage.getItem('resetEmail')
		if (storedEmail) {
			setValue('email', storedEmail)
		}
	}, [setValue])

	const onSubmit = async (data: passwordResetSubmission) => {
		try {
			setLoading(true)
			await confirmResetPassword({
				username: data.email,
				confirmationCode: data.confirmationCode,
				newPassword: data.newPassword,
			})
			setLoading(false)
			history.push('/login')
		} catch (e: any) {
			toast.error(e.message)
			setLoading(false)
		}
	}

	return (
		<Flex
			w='100%'
			h='100vh'
			align='center'
			justify='center'
			bgImage={`url(https://mihostedimages.s3.amazonaws.com/Login_bg.png)`}
			bgSize='cover'
			bgPosition='center'
			p={{ base: 4, md: 0 }}
		>
			<Flex w='100%' h='100%' justify='center' align='center'>
				<Box {...containerStyles}>
					<Helmet>
						<title>Valence Intelligence | Reset Password</title>
					</Helmet>
					<Box {...formStyles} onSubmit={handleSubmit(onSubmit)}>
						<Image src={Logo} alt='logo' m='20px' height={{ base: '60px', md: '80px' }} />

						<Box {...inputStyles} mt='10px'>
							<label style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold' }}>
								Email
							</label>
							<FormInput
								fontSize='14px'
								name='email'
								control={control}
								defaultValue=''
								rules={{
									validate: {
										checkEmail: (v: string) => validateEmail(v),
									},
								}}
								label=''
								Icon={<EmailIcon fontSize='x-large' color='gray.400' />}
								helperText={errors?.email?.message}
								error={errors?.email ? true : false}
								readOnly
							/>
						</Box>
						<Box {...inputStyles} mt='10px'>
							<label style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold' }}>
								Confirmation Code
							</label>
							<FormInput
								name='confirmationCode'
								control={control}
								defaultValue=''
								Icon={<MdConfirmationNumber fontSize='x-large' color='#A8A9AD' />}
								rules={{ required: true, maxLength: 10 }}
								helperText={errors?.confirmationCode?.message}
								error={errors?.confirmationCode ? true : false}
								placeholder='Enter Code'
								fontSize='14px'
								label=''
							/>
						</Box>
						<Box {...inputStyles} mt='10px'>
							<label style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold' }}>
								New Password
								<Tooltip
									bgColor='#70CBCF'
									label={
										<div>
											<span>Passwords must contain:</span>
											<ul>
												<li>8 characters</li>
												<li>An uppercase letter</li>
												<li>A lowercase letter</li>
												<li>A special character</li>
												<li>A number</li>
											</ul>
										</div>
									}
								>
									<InfoIcon color='#70CBCF' style={{ marginLeft: '4px' }} />
								</Tooltip>
							</label>
							<PasswordInput
								name='newPassword'
								control={control}
								helperText={errors?.newPassword?.message}
								error={errors?.newPassword ? true : false}
								rules={{
									validate: {
										checkPassword: (v: string) => validatePassword(v),
									},
								}}
								label=''
								showPassword={showNewPassword}
							/>
							<Flex alignItems='center' color='gray' mt={2} position='absolute' right='-10px'>
								<Switch
									size='sm'
									colorScheme='blue'
									id='show-new-password'
									mr='2'
									onChange={toggleShowNewPassword}
									isChecked={showNewPassword}
								/>
								<FormLabel htmlFor='show-new-password' fontSize='xs' mb='0'>
									{showNewPassword ? 'Hide' : 'Show'}
								</FormLabel>
							</Flex>
						</Box>
						<Box {...inputStyles} mt='10px'>
							<label style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold' }}>
								Confirm Password
								<Tooltip
									bgColor='#70CBCF'
									label={
										<div>
											<span>Passwords must contain:</span>
											<ul>
												<li>8 characters</li>
												<li>An uppercase letter</li>
												<li>A lowercase letter</li>
												<li>A special character</li>
												<li>A number</li>
											</ul>
										</div>
									}
								>
									<InfoIcon color='#70CBCF' style={{ marginLeft: '4px' }} />
								</Tooltip>
							</label>
							<PasswordInput
								name='confirmNewPassword'
								control={control}
								helperText={errors?.confirmNewPassword?.message}
								error={errors?.confirmNewPassword ? true : false}
								rules={{
									validate: {
										checkPassword: (v: string) => checkPasswordMatch(v, watch('newPassword')),
									},
								}}
								label=''
								showPassword={showConfirmPassword}
							/>
							<Flex alignItems='center' color='gray' mt={2} position='absolute' right='-10px'>
								<Switch
									size='sm'
									mr='2'
									colorScheme='blue'
									id='show-confirm-password'
									onChange={toggleShowConfirmPassword}
									isChecked={showConfirmPassword}
								/>
								<FormLabel htmlFor='show-confirm-password' fontSize='xs' mb='0'>
									{showConfirmPassword ? 'Hide' : 'Show'}
								</FormLabel>
							</Flex>
						</Box>

						<Box mt='25px' w='100%'>
							<Flex align='center' justify='center' w='100%'>
								<Button
									color='white'
									size='md'
									height='40px'
									width={{ base: '80%', md: '400px' }}
									type='submit'
									isDisabled={loading || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword}
									isLoading={loading}
									sx={{
										backgroundColor:
											loading || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword
												? 'gray.600'
												: '#149BD7',
									}}
								>
									Update Password
								</Button>
							</Flex>

							<Flex align='center' justify='center' w='100%' marginTop='30px'>
								<ChakraLink as={ReactRouterLink} to='/login' color='#149BD7' fontSize='12px'>
									Back to Login
								</ChakraLink>
							</Flex>
						</Box>
					</Box>
				</Box>
			</Flex>

			<Box position='fixed' bottom='10px' width='100%' textAlign='center' color='white' fontSize='xs' fontWeight='bold'>
				© 2024, Valence Intelligence
			</Box>
		</Flex>
	)
}

const containerStyles: BoxProps = {
	bgColor: 'white',
	p: '30px',
	borderRadius: '10px',
	boxShadow: 'md',
	position: 'relative',
}

const formStyles: BoxProps = {
	as: 'form',
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	alignItems: 'center',
}
const inputStyles: BoxProps = {
	width: '100%',
	m: '20px',
	position: 'relative',
}

export default PasswordReset
