import { Box, Flex, Center, Heading, OrderedList, ListItem } from '@chakra-ui/react'
import Helmet from 'react-helmet'

function PrivacyPolicy() {
	return (
		<div>
			<Helmet>
				<title>Valence Intelligence | Privacy Policy</title>
			</Helmet>
			<Flex justifyContent='center'>{getPrivacyPolicy()}</Flex>
		</div>
	)
}

export default PrivacyPolicy

function getPrivacyPolicy() {
	return (
		<Box w={['100%', '90%', '70%', '60%']}>
			<Center my={5}>
				<Heading>Valence Intelligence Privacy Policy</Heading>
			</Center>
			<Center mb={5}>
				<strong>Last Updated May 9, 2024</strong>
			</Center>
			<Center my={3}>
				Valence Intelligence LLC (“us”, “we”, or “our”) operates the https://valenceintel.com website ( “Service”).
			</Center>
			<Heading my={3}>Data Categories & Collection Purposes</Heading>
			<p>
				To enhance our service, we collect various types of information. By visiting our website(s) or using our
				Services, you provide us with the following categories of personal data:
			</p>
			<OrderedList>
				<ListItem>
					Customer Account and Registration Data: This includes your email address, first and last name, phone number,
					address, state, province, ZIP/postal code, city, cookies, and usage data. It is necessary for creating an
					account, providing support, and maintaining your account.
				</ListItem>

				<ListItem>
					Service Data (Including Session, Location, and Usage Data): We gather information on how the Service is
					accessed and used. This includes data automatically logged by the website or Service, such as session
					duration, hardware and device details, IP addresses, location, language settings, operating system
					information, unique device identifiers, and other diagnostic data. This information is crucial for delivering,
					operating, and enhancing our Services.
				</ListItem>
			</OrderedList>
			<Heading my={3}>Cookies & Other Web Site Technologies</Heading>
			<p>
				We utilize cookies and similar tracking technologies to monitor activity on our Service and store certain
				information. You can configure your browser to reject cookies or notify you when a cookie is being sent.
				However, note that disabling cookies may limit your access to certain parts of our Service.
			</p>

			<Heading my={3}>How We Use Your Data</Heading>
			<p>The collected data by Valence Intelligence LLC. serves multiple purposes, which include:</p>
			<OrderedList>
				<ListItem>Providing and ensuring the maintenance of our Service.</ListItem>

				<ListItem>Notifying you about any changes made to our Service.</ListItem>

				<ListItem>
					Enabling your participation in interactive features of our Service, should you choose to do so.
				</ListItem>

				<ListItem>Offering customer care and support services.</ListItem>

				<ListItem>Providing analysis and valuable insights to enhance the quality of our Service.</ListItem>
				<ListItem>Monitoring the usage patterns of our Service.</ListItem>
				<ListItem>Detecting, preventing, and addressing any technical issues that may arise.</ListItem>
			</OrderedList>
			<Heading my={3}>Data Sharing</Heading>
			<p>
				Your Personal Data and other information may be transferred to and stored on computers outside your
				jurisdiction, including countries with different data protection laws than those in your state, province, or
				country. If you are outside the United States and choose to provide your Personal Information to us, please be
				aware that we may transfer your data to the United States or other countries where our service providers are
				located. These countries may not offer the same level of data protection as your jurisdiction. By consenting to
				this Privacy Policy and submitting your information, you acknowledge and agree to such data transfers. Valence
				Intelligence LLC. will take all reasonable steps to ensure that your data is treated securely and in accordance
				with this Privacy Policy. We will not transfer your Personal Data to any organization or country without
				adequate controls in place to safeguard your data and personal information.
			</p>

			<Heading my={3}>Data Disclosure</Heading>
			<p>
				Under certain circumstances, Valence Intelligence LLC may need to disclose your Personal Data in good faith when
				it is deemed necessary to:
			</p>
			<OrderedList>
				<ListItem>Comply with any applicable legal obligations.</ListItem>

				<ListItem>Protect and defend the rights or property of Valence Intelligence LLC.</ListItem>

				<ListItem>Investigate or prevent potential wrongdoing associated with the Service.</ListItem>

				<ListItem>Safeguard the personal safety of Service users or the general public.</ListItem>

				<ListItem>Mitigate potential legal liability.</ListItem>
			</OrderedList>

			<Heading my={3}>Security Of Data</Heading>

			<p>
				We place great importance on the security of your data and make diligent efforts to safeguard your personal
				information. However, it is important to note that no method of transmission over the internet or electronic
				storage is entirely secure. Therefore, while we employ commercially acceptable measures to protect your personal
				data, we cannot guarantee its absolute security.
			</p>

			<Heading my={3}>Third Parties/Service Providers:</Heading>
			<p>
				To assist in delivering our services and conducting related tasks on our behalf, such as service analysis, we
				may engage third-party companies and individuals. These entities are only permitted to access your personal data
				for the purpose of performing these tasks and are bound by confidentiality obligations, preventing them from
				disclosing or using the data for any other purposes.
			</p>

			<Heading my={3}>Analytics</Heading>
			<p>
				We may utilize third-party service providers, like Google Analytics, to analyze and monitor the usage of our
				service. Google Analytics tracks and reports website traffic and may use the collected data to personalize and
				contextualize ads within its advertising network. You can install the Google Analytics opt-out browser add-on if
				you wish to prevent your activity on our service from being available to Google Analytics. For more information
				about Google’s privacy practices, please refer to the Google Privacy & Terms web page.
			</p>
			<Heading my={3}>Links To Other Websites</Heading>
			<p>
				Our Service may contain links to third-party websites that are not operated by us. We strongly advise you to
				review every site’s privacy policies. We have no control over and assume no responsibility for these third-party
				sites or services’ content, privacy policies, or practices.
			</p>
			<Heading my={3}>Childrens Privacy</Heading>
			<p>
				Our service is not intended for individuals under the age of 18 (“Children”), and we do not knowingly collect
				personally identifiable information from anyone under this age. If you are a parent or guardian and become aware
				that your child has provided us with personal data, please contact us. If we discover that we have collected
				personal data from a child without verifiable parental consent, we take steps to remove that information from
				our servers.
			</p>
			<Heading my={3}>Changes To This Privacy Policy</Heading>
			<p>
				We may occasionally update our privacy policy and notify you of any changes by posting the revised policy on our
				website. Additionally, we will send an email and/or display a prominent notice on our service before the changes
				take effect. The “effective date” at the top of this privacy policy will also be updated. We recommend reviewing
				this privacy policy periodically for any updates.
			</p>

			<Heading my={3}>Contact Us</Heading>

			<Box my={3}>
				<p>info@valenceintel.com</p>
				<p>Valence Intelligence</p>
				<p>136 S Main St #400</p>
				<p>Salt Lake City, UT 84101</p>
			</Box>
		</Box>
	)
}
