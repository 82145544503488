import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useForm, Controller } from 'react-hook-form'

import PrimaryButton from 'components/UIComponents/PrimaryButton'

import { Checkbox } from '@chakra-ui/react'
import { MdOutlineAccountCircle as AccountCircle } from 'react-icons/md'
import { useHistory, Link, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'

import { validatePassword, validateRequired, checkPasswordMatch } from 'utils/formValidation'
import PasswordInput from 'components/PasswordInput'
import FormInput from 'components/FormInput'

import styles from './SignupInvite.module.scss'
import Logo from 'images/ValenceLogoLight.png'

import Laptop from 'images/laptop.png'
import api from 'api'
import moment from 'moment'
import * as Sentry from '@sentry/react'

type submission = {
	name: string
	password: string
	confirmPassword: string
	terms: boolean
}
const SignupInvite = () => {
	const [loading, setLoading] = useState(true)
	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState<null | string>(null)
	const [invite, setInvite] = useState<any>(null)
	const history = useHistory()
	const { invite: urlInviteValue } = useParams<any>()

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<submission>()
	const onSubmit = async (formData: submission) => {
		try {
			setSubmitting(true)
			await api.post('/signup/invitee', {
				...formData,
				InviteCode: urlInviteValue,
			})
			history.push(`/check_email?email=${invite.Email}`)
		} catch (e: any) {
			toast.error(e?.response?.data?.message)
			setSubmitting(false)
		}
	}
	useEffect(() => {
		const fetchData = async () => {
			//load invite data
			try {
				const result = await api.get('/signup/invite', {
					params: { InviteCode: urlInviteValue },
				})
				if (moment().isAfter(result?.data?.ExpireTimeStamp)) {
					setError('Invite expired.  Please contact your account manager to get a new invite.')
				} else {
					setInvite(result.data)
					setLoading(false)
				}
			} catch (e: any) {
				setError(e.response.data.error.message)
				Sentry.captureException(e)
			}
		}
		void fetchData()
	}, [urlInviteValue])

	return (
		<div className={styles.container}>
			<Helmet>
				<title>Valence Intelligence | Invite Signup</title>
			</Helmet>
			<div className={styles.salesHalf}>
				<div className={styles.headerText}>Grow. Thrive. Win.</div>
				<div className={styles.subHeader}>
					VALENCE
					<br /> INTELLIGENCE
				</div>
				<div className={styles.laptop}>
					<img src={Laptop} alt='laptop' />
				</div>
			</div>

			<div className={styles.formContainer}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.logo}>
						<img src={Logo} alt='logo' />
					</div>
					{error && <div>{error}</div>}
					{invite && (
						<div>
							Welcome {invite.Email} {invite.Organization} has invited you as a user to their account.
						</div>
					)}
					{!error && (
						<>
							<div>
								<FormInput
									name='name'
									control={control}
									rules={{
										validate: {
											checkRequired: (v: string) => validateRequired(v),
										},
									}}
									label='Full Name'
									helperText={errors?.name?.message}
									error={errors?.name ? true : false}
									Icon={<AccountCircle fontSize='large' />}
								/>
							</div>
							<div>
								<PasswordInput
									name='password'
									control={control}
									rules={{
										validate: {
											checkPassword: (v: string) => validatePassword(v),
										},
									}}
									helperText={
										errors?.confirmPassword?.message ? (
											errors?.confirmPassword?.message
										) : (
											<div className={styles.pwHelperText}>
												<span>Passwords must contain:</span>
												<ul className={styles.pwRequirementsList}>
													<li>8 characters</li>
													<li>A uppercase letter</li>
													<li>A lowercase letter,</li>
													<li>A special character</li>
													<li>A number</li>
												</ul>
											</div>
										)
									}
									error={errors?.password ? true : false}
									label='Password'
								/>
							</div>
							<div>
								<PasswordInput
									name='confirmPassword'
									control={control}
									rules={{
										validate: {
											checkPassword: (v: string) => checkPasswordMatch(v, watch('password')),
										},
									}}
									error={errors?.confirmPassword ? true : false}
									helperText={errors?.confirmPassword?.message}
									label='Confirm Password'
								/>
							</div>
							<div>
								<div className={styles.privacy}>
									<div>
										<Controller
											name='terms'
											control={control}
											rules={{ required: true }}
											render={({ field }) => (
												<Checkbox isChecked={field.value} onChange={field.onChange} pr='5px' colorScheme='primary' />
											)}
										/>
									</div>
									I agree to all
									<Link to='/terms_and_conditions' target='_blank' rel='noreferrer'>
										Terms
									</Link>
									and
									<a href='https://sifted.com/privacy-policy/' target='_blank' rel='noreferrer'>
										Privacy Policy
									</a>
								</div>
								{errors.terms && <div className={styles.termError}>Required</div>}
							</div>
							<div>
								<PrimaryButton type='submit' isDisabled={loading} isLoading={submitting}>
									Create Account
								</PrimaryButton>
							</div>
						</>
					)}
				</form>
			</div>
		</div>
	)
}

export default SignupInvite
