import { Button, ButtonProps } from '@chakra-ui/react'

const SecondaryButton = ({ children, ...props }: ButtonProps) => {
	return (
		<Button
			colorScheme={props.colorScheme ?? 'blue'}
			borderColor={props.borderColor ?? 'gray.200'}
			variant={props.variant ?? 'outline'}
			{...props}
		>
			{children}
		</Button>
	)
}

export default SecondaryButton
