import { createElement } from 'react'
import { Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import ErrorFallback from 'components/ErrorFallback'

type UnprotectedRouteProps = {
	component: any
	tag: string
	path: string
	exact?: boolean
}
const UnprotectedRoute = ({ component, tag, path, exact = false }: UnprotectedRouteProps) => {
	return (
		<Route
			exact={exact}
			path={path}
			render={() => (
				<Sentry.ErrorBoundary
					fallback={({ error }) => <ErrorFallback error={error} />}
					beforeCapture={(scope) => scope.setTag('location', tag)}
				>
					{createElement(component)}
				</Sentry.ErrorBoundary>
			)}
		/>
	)
}

export default UnprotectedRoute
