import React, { useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import {
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	FormControl,
	HStack,
	Text,
	Switch,
	Flex,
} from '@chakra-ui/react'
import { LockIcon } from '@chakra-ui/icons'

type PasswordInputProps = {
	name: string
	defaultValue?: string
	label: string
	rules: any
	control: Control<any>
	helperText: any
	error: boolean
	placeholder?: string
	Icon?: React.ReactNode
}

const PasswordInput: React.FC<PasswordInputProps> = ({
	name,
	defaultValue = '',
	rules,
	control,
	error,
	helperText,
	placeholder,
}: PasswordInputProps) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field }) => (
				<FormControl isInvalid={error}>
					<HStack justifyContent='space-between'></HStack>
					<InputGroup>
						<InputLeftElement color='gray.400'>
							<LockIcon fontSize='large' />
						</InputLeftElement>
						<Input {...field} type={showPassword ? 'text' : 'password'} fontSize='14px' placeholder={placeholder} />
					</InputGroup>
					<FormErrorMessage>{helperText}</FormErrorMessage>
					<Flex alignItems='center' w={'100%'} justifyContent={'flex-end'}>
						<Switch size='sm' isChecked={showPassword} onChange={() => setShowPassword((prev) => !prev)} />
						<Text fontSize='sm' marginLeft='10px'>
							{showPassword ? 'Hide' : 'Show'}
						</Text>
					</Flex>
				</FormControl>
			)}
		/>
	)
}

export default PasswordInput
