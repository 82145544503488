import api from 'api'

export const fetcher = (url: string) => {
	if (api.defaults.headers.common['accesstoken'] === undefined) throw new Error('accesstoken is undefined')
	return api.get(url).then((res) => res.data)
}

export const fetcherWithParams = ({ url, params }: { url: string; params: any }) => {
	if (api.defaults.headers.common['accesstoken'] === undefined) throw new Error('accesstoken is undefined')
	return api.get(url, { params }).then((res) => res.data)
}

export const fetcherSingleUseWithParams = (url: string, { arg }: { arg: any }) => {
	if (api.defaults.headers.common['accesstoken'] === undefined) throw new Error('accesstoken is undefined')
	return api.get(url, { params: { ...arg } }).then((res) => res.data)
}

export const multiFetcher = (urls: string[]) => {
	return Promise.all(urls.map((url) => fetcher(url)))
}

export const multiFetcherWithParams = ({ urls, params }: { urls: string[]; params: any }) => {
	return Promise.all(urls.map((url) => fetcherWithParams({ url, params })))
}
