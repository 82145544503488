export enum manualCaseSteps {
	manualCaseStep1 = 'manual-Case-Step-1',
	manualCaseStep2 = 'manual-Case-Step-2',
	manualCaseStep3 = 'manual-Case-Step-3',
}

const tourSteps = [
	{
		selector: `[data-tour=${manualCaseSteps.manualCaseStep1}]`,
		content: `You can track a case and any reimbursements not identified by our platform.`,
	},
	{
		selector: `[data-tour=${manualCaseSteps.manualCaseStep2}]`,
		content: `Once you have opened the case in Seller Central, click here to add the case to our system.`,
	},
	{
		selector: `[data-tour=${manualCaseSteps.manualCaseStep3}]`,
		content:
			'The case will appear here, once a reimbursement shows up in Seller Central, please allow up to 5 days for our system to pull it in and attribute it to the case.',
	},
]

export default tourSteps
