import toast from 'react-hot-toast'

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isAuthorized(role: string): boolean {
	return role === 'Owner' || role === 'Developer'
}

export const isNullOrUndefined = (value: any): boolean => {
	return value === undefined || value === null
}

export function formatCurrency(value: number, currency: string, digits?: number) {
	return new Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: digits ?? 2,
		maximumFractionDigits: digits ?? 2,
	}).format(value)
}

export function handleReactQueryError(error: any, message: string) {
	if (error instanceof Error) {
		if ((error as Error).message !== 'accesstoken is undefined') {
			console.log(error)
			toast.error(message)
		}
	}
}

export function shouldRender(value: any) {
	return value ? true : false
}

export function openSCLinkAccount(subId: string | undefined) {
	if (!subId) return
	window.open(
		`${import.meta.env.VITE_SELLER_CENTRAL_AUTH_BASE_URL}?application_id=${
			import.meta.env.VITE_APPLICATION_ID
		}&state=${subId}&redirect_uri=${import.meta.env.VITE_SELLER_CENTRAL_REDIRECT_URI}`,
		'_blank'
	)
}

export function displayError(error: string) {
	toast.error(error)
}
