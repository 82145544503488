import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Helmet from 'react-helmet'
import { resetPassword } from 'aws-amplify/auth'
import { toast } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, Stack } from '@chakra-ui/react'

import FormInput from 'components/FormInput'
import { validateEmail } from 'utils/formValidation'
import Logo from 'images/ValenceLogoLight.png'

import { Box, BoxProps, Button, Flex, Image } from '@chakra-ui/react'
import { EmailIcon } from '@chakra-ui/icons'

type submission = {
	email: string
}

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false)
	const [isEmailEmpty, setIsEmailEmpty] = useState(true)

	const history = useHistory()

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<submission>()

	const emailValue = watch('email', '')

	useEffect(() => {
		setIsEmailEmpty(emailValue === '')
	}, [emailValue])

	const onSubmit = async (data: submission) => {
		try {
			setLoading(true)
			await resetPassword({ username: data.email })
			setLoading(false)
			localStorage.setItem('resetEmail', data.email)
			history.push('/reset_password', { email: data.email })
		} catch (e: any) {
			toast.error(e.message)
			setLoading(false)
		}
	}

	return (
		<Flex
			w='100%'
			h={{ base: '100%', md: '100vh' }}
			align='center'
			justify='center'
			bgImage={`url(https://mihostedimages.s3.amazonaws.com/Login_bg.png)`}
			bgSize='cover'
			bgPosition='center'
			p={{ base: 4, md: 0 }}
		>
			<Stack w='100%' h='100%' align='center' justify='center' direction='column' gap={5}>
				6
				<Box {...containerStyles}>
					<Helmet>
						<title>Valence Intelligence | ForgotPassword</title>
					</Helmet>
					<Box {...formStyles} onSubmit={handleSubmit(onSubmit)}>
						<Image src={Logo} alt='logo' m='20px' height={{ base: '60px', md: '80px' }} />
						<Box width='100%' mt='20px'>
							<FormInput
								placeholder='Enter Email Address'
								fontSize='14px'
								name='email'
								control={control}
								type='email'
								rules={{
									validate: {
										checkEmail: (v: string) => validateEmail(v),
									},
								}}
								label='Reset Password'
								Icon={<EmailIcon fontSize='x-large' color='gray.400' />}
								helperText={errors?.email?.message}
								error={errors?.email ? true : false}
							/>
						</Box>
						<Box mt='30px' width='100%'>
							<Button
								type='submit'
								isDisabled={loading || isEmailEmpty}
								isLoading={loading}
								width='100%'
								bgColor={isEmailEmpty ? 'gray.400' : '#149BD7'}
								_hover={isEmailEmpty ? {} : { bg: '#149BD7' }}
								cursor={isEmailEmpty ? 'not-allowed' : 'pointer'}
								color={'white'}
							>
								Reset Password
							</Button>
						</Box>
					</Box>
					<Flex align='center' justify='flex-end' w='100%' mt='25px'>
						<ChakraLink as={ReactRouterLink} to='/login' color='#149BD7' fontSize='12px'>
							Back to Login
						</ChakraLink>
					</Flex>
				</Box>
				<Box width='100%' textAlign='center' color='white' fontSize='xs' fontWeight='bold'>
					© 2024, Valence Intelligence
				</Box>
			</Stack>
		</Flex>
	)
}

export default ForgotPassword

const containerStyles: BoxProps = {
	bgColor: 'white',
	p: { base: '20px', md: '30px' },
	borderRadius: '10px',
	boxShadow: 'md',
	width: { base: '100%', md: 'auto' },
	maxW: { base: '100%', md: '500px' },
	minW: { base: '100%', md: '380px' },
}

const formStyles: BoxProps = {
	as: 'form',
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	alignItems: 'center',
	width: '100%',
}
