import { Box, Flex, Center, Heading, OrderedList, ListItem, UnorderedList } from '@chakra-ui/react'
import Helmet from 'react-helmet'

function TermsAndConditions() {
	return (
		<div>
			<Helmet>
				<title>Valence Intelligence | Terms and Conditions</title>
			</Helmet>
			<Flex justifyContent='center'>{getTermsAndConditions()}</Flex>
		</div>
	)
}

export default TermsAndConditions

function getTermsAndConditions() {
	return (
		<Box w={['100%', '90%', '70%', '60%']}>
			<Center my={5}>
				<Heading>Valence Intelligence Terms of Service</Heading>
			</Center>
			<Center mb={5}>
				<strong>Last Updated May 9, 2024</strong>
			</Center>
			<Center my={3}>
				<strong>
					Please read these Terms of Service (&ldquo;Terms&rdquo;) carefully. BY USING THE SERVICE AND THE SITE, YOU
					AGREE TO THESE TERMS. IF YOU DO NOT AGREE, DO NOT USE THE SERVICE OR SITE.
				</strong>
			</Center>
			<Heading my={3}>Services</Heading>
			<OrderedList>
				<ListItem>
					<strong>Service and Website</strong>. Valence Intelligence (referred to, as applicable, as "Valence
					Intelligence", the "Service", "we" or "us") provides tools and services designed to help sellers on the Amazon
					platform (the "Service").
				</ListItem>

				<ListItem>
					<strong>Customer</strong>. As a customer of the Service or a representative of an entity that is a customer of
					the Service, you are a &ldquo;Member&rdquo; according to this agreement (or &ldquo;you&rdquo;). You represent
					that you have full power, capacity and authority to accept these Terms on behalf of yourself or your employer.
				</ListItem>

				<ListItem>
					<strong>Account Requirements</strong>. To set up an account and use the Service you must:
				</ListItem>

				<UnorderedList>
					<ListItem>
						Be 18 years of age, or the age of majority in your province, territory or country, to become a Member.
						Individuals under the age of 18, or applicable age of majority, may use the Service only with the
						involvement of a parent or legal guardian, under such person's account and otherwise subject to these Terms.
					</ListItem>
					<ListItem>
						Provide your legal full name, valid email address, company name, and any other information we request to
						complete your account-signup process.
					</ListItem>
					<ListItem>
						Provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted method of
						payment, as may be updated from time to time and which may include payment through your account with a third
						party.
					</ListItem>
					<ListItem>
						Personally and manually create your account without using any automated means, except for any auto-complete
						feature offered by your internet service provider. A third party may not create an account for you and you
						must not allow any third party to use your information to create an account.
					</ListItem>
				</UnorderedList>

				<ListItem>
					<strong>Account Ownership.</strong> The member who created the account and whose Payment Method is charged
					(the "Account Owner") has access to and control over the account and is responsible for any activity that
					occurs through the account. To maintain control over the account and prevent anyone from accessing the
					account, the Account Owner should maintain control over access to the Service and not reveal the password or
					details of the Payment Method associated with the account to anyone. Account owners are not allowed to share
					their account, log-in or any other access related credentials with any other party unless explicitly stated
					otherwise in the details related to the specific plan they purchased. Absent an explicit multi-user
					authorization, all accounts are intended for single-use only. You are responsible for updating and maintaining
					the accuracy of the information you provide to us relating to your account. We can terminate your account or
					place your account on hold in order to protect you and/or Valence Intelligence. You must immediately notify us
					of any unauthorized use of your account. We will not be liable for any loss or damage from your failure to
					comply with this security obligation.
				</ListItem>
				<ListItem>
					<strong>Enterprise Services</strong>.&nbsp; Valence Intelligence offers an enterprise package (the "Enterprise
					Service") to eligible customers.&nbsp; Through the Enterprise Service, Valence Intelligence will identify
					Amazon's errors in (1) handling your inventory associated with the Fulfillment by Amazon service; (2)
					accounting for sales transactions reported through the Amazon Seller Central Service; and (3) processing
					Amazon customers' product returns.&nbsp; Valence Intelligence then secures cash reimbursements and recovered
					inventory (the "Reimbursements") for these Amazon errors.&nbsp; You may subscribe to the Enterprise Service if
					you are currently subscribed to a Subscription (as defined below) at a rate of $59 per month or higher.&nbsp;
					If you subscribe to the Enterprise Service, in addition to your fee for the Subscription, Valence Intelligence
					will charge you a fee (the "Enterprise Service Fee") equal to 10% or 15% (depending on subscription level) of
					the Reimbursements that Valence Intelligence secures you from Amazon or such other percentage as is indicated
					in your account settings in the Valence Intelligence Customer Portal (the "Service Rate"). In order to use the
					Enterprise Service, you must provide access to your Amazon account including (1) limited sign-in and
					permission-based access to the Amazon account; and (2) developer based access to your Amazon account's
					reporting features through the Amazon Selling Partner API.&nbsp; You must maintain Valence Intelligence's
					access to your Amazon account during the term of your subscription to the Enterprise Service and for 120 days
					following the deactivation of the Enterprise Service.&nbsp; If you are eligible to subscribe to the Enterprise
					Service, you may do so at any time and your obligation to pay the Enterprise Service Fee begins as soon as you
					receive any Reimbursement.&nbsp;
				</ListItem>

				<p>In using providing the Enterprise Service, Valence Intelligence will:</p>
				<UnorderedList>
					<ListItem>
						Limit use of its access to your Amazon account to providing the Enterprise Service and also restrict this
						access to the Valence Intelligence employees specifically authorized to provide the Enterprise Service. No
						third party, such as a contractor, will be used in providing the Enterprise Service.
					</ListItem>
					<ListItem>
						Copy certain data in your Amazon account (&ldquo;Enterprise Service Customer Data&rdquo;) to Valence
						Intelligence's technical platform where the Enterprise Service Customer Data will be stored, formatted and
						compiled with other data.
					</ListItem>
					<ListItem>
						Display certain of the Enterprise Service Customer Data as well as information about Valence Intelligence's
						activities in performing the Enterprise Service on the Valence Intelligence Customer Portal website
						(&ldquo;Customer Portal&rdquo;).
					</ListItem>
					<ListItem>
						Undertake reasonable efforts to ensure the security of Valence Intelligence's access to your Amazon account,
						Enterprise Service Customer Data on the Valence Intelligence platform and your access to the Customer
						Portal.
					</ListItem>
					<ListItem>
						Protect and preserve the confidentiality of Enterprise Service Customer Data, including by not sharing such
						data with any party except as required by law or to perform the Enterprise Service.
					</ListItem>
				</UnorderedList>
				<p>To use the Enterprise Service, you will:</p>
				<UnorderedList>
					<ListItem>
						Provide truthful and accurate information and documentation when requested by Valence Intelligence.
					</ListItem>
					<ListItem>
						Make your best efforts to supply to Valence Intelligence, upon request, information and certain types of
						documentation, including about your shipments to Amazon fulfillment centers, the packaged product size and
						weight specifications for the products offered in the Fulfillment By Amazon program, and Amazon fulfillment
						center removal order shipment receiving records.
					</ListItem>
					<ListItem>
						Not engage in any activity that interferes with Valence Intelligence providing the Enterprise Service,
						including, without prior arrangement, addressing the Amazon Seller Support cases that Valence Intelligence
						submits in connection with your Amazon Account.
					</ListItem>
					<ListItem>
						Not attempt to decipher, decompile, reverse engineer or otherwise discover the methodology of the business
						processes or source code of the software that make up the Enterprise Service, including the Customer Portal.
					</ListItem>
					<ListItem>
						Protect and preserve the confidentiality of the proprietary information that Valence Intelligence shares
						with you, which may or may not be designated as confidential, the scope of the Enterprise Service provided,
						the business processes and software features used in providing the Enterprise Service, and other
						information:
					</ListItem>
				</UnorderedList>
			</OrderedList>
			<Heading my={3}>Payment</Heading>
			<OrderedList>
				<ListItem>
					<strong>Billing. </strong>Valence Intelligence may offer the Service through a variety of billing methods,
					which include, but are not limited to, one-time subscriptions, monthly recurring subscriptions, and annual
					recurring subscriptions (each, a &ldquo;Subscription&rdquo;). Valence Intelligence will bill you on the date
					you subscribe to the Service (&ldquo;Subscription Date&rdquo;), and will continue to bill you in accordance
					with your selected Subscription{' '}
					<strong>until you cancel the Service. You must cancel your Subscription </strong>
					<strong>at least one day</strong>
					<strong> prior to the next billing date to avoid a Subscription renewal.</strong>
					&nbsp; If you subscribe to the Enterprise Service, we will also bill you each month for the previous month's
					Enterprise Service Fee based on the Service Rate multiplied by the total Reimbursements for the applicable
					month.&nbsp; Fees will be billed to your Payment Method on the calendar day corresponding to the Subscription
					Date. Subscription fees are fully earned upon payment. Enterprise Service Fees are earned by Valence
					Intelligence on your receipt of the applicable Reimbursements. In some cases your payment date may change, for
					example if your Payment Method has not successfully settled or if you upgrade your plan outside of your
					current billing cycle.
				</ListItem>

				<ListItem>
					<strong>Payment Methods</strong>. To use the Service you must provide a valid Payment Method. You can update
					your Payment Method by going to the "Settings" page of our Website under &ldquo;Account Billing&rdquo;.
					Following any update, you authorize us to continue to charge the applicable Payment Method. All fees are
					charged in advance of the month in which you will use the Service. You authorize us to charge any Payment
					Method associated to your account in case your primary Payment Method is declined or no longer available to us
					for payment of your subscription fee. You remain responsible for any uncollected amounts. You authorize us to
					charge your Payment Method for past due balances, including Enterprise Service Fees, when a new Payment Method
					is added and when the Account you have purchased changes status via an upgrade or downgrade.&nbsp; If your
					account is past due for more than 7 days, we will automatically downgrade your account to the
					&ldquo;free&rdquo; version which has limited functionality and does not provide access to many of our paid
					service tools.&nbsp; Should a downgrade occur, you may move your account to the service tier you desire by
					paying the designated monthly fees in advance. If your Enterprise Service Fee is past due for more than 30
					days, we will charge a late fee equal to 1.5% per month or the highest amount permitted under applicable law.
					If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not
					cancel your account, we may suspend your access to the service until we have successfully charged a valid
					Payment Method.&nbsp; For some Payment Methods, the issuer may charge you certain fees relating to the
					processing of your Payment Method. Check with your Payment Method service provider for details.&nbsp; You will
					reimburse us for the costs of collection for any late payments including reasonable attorney's fees. If you
					have subscribed to the Enterprise Service and your account is downgraded to the "free" version, your access to
					the Enterprise Service will be suspended at the end of the billing cycle in which such downgrade occurs.
				</ListItem>

				<ListItem>
					<strong>Cancellation.</strong> You can cancel your membership at any time, and you will continue to have
					access to the Service through the end of your pre-paid billing cycle. We do not provide refunds or credits for
					any partial membership periods. Cancellation requests should be completed by emailing{' '}
					<a href='mailto:client_support@valenceintel.com' target='_blank' rel='noopener noreferrer'>
						client_support@valenceintel.com
					</a>{' '}
					with your organization name and account owner email. If you cancel your membership, your account will
					automatically close at the end of your current billing period.
				</ListItem>

				<ListItem>
					<strong>Changes to the Price and Subscription Plans.</strong> We reserve the right to change our subscription
					plans or adjust pricing for our Service or the Enterprise Service at any time as we may determine in our sole
					and absolute discretion. Except as otherwise expressly provided for in these Terms, any price changes or
					changes to your subscription plan will take effect following notice to you.
				</ListItem>

				<ListItem>
					<strong>No Refunds.</strong> Payments are nonrefundable and there are no refunds or credits for partially used
					periods. Following any cancellation, however, you will continue to have access to the service through the end
					of your current billing period.
				</ListItem>
			</OrderedList>
			<Heading my={3}>Disclaimers of Warranties and Limitations on Liability</Heading>
			<OrderedList>
				<ListItem>
					<strong>Disclaimer of Warranties.&nbsp; </strong>THE MATERIALS ON THIS WEBSITE ARE PROVIDED ON AN &lsquo;AS
					IS&rsquo; BASIS.&nbsp; Valence Intelligence MAKES NO WARRANTIES, EXPRESSED IMPLIED, AND HEREBY DISCLAIMS ALL
					OTHER WARRANTIES INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
					FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR OTHER VIOLATION OF RIGHTS.&nbsp;
					FURTHER, Valence Intelligence DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS THAT THE CONTENT OR SERVICE WILL BE
					UNINTERRUPTED, OR ERROR FREE AND MAKES NO WARRANTIES CONCERNING THE ACCURACY OR COMPLETENESS, LIKELY RESULTS,
					OR RELIABILITY OF THE USE OF THE MATERIALS ON ITS WEBSITE OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY
					SITES LINKED TO THIS SITE.&nbsp; YOU ARE RESPONSIBLE FOR BACKING UP YOUR DATA AND Valence Intelligence SHALL
					HAVE NO LIABILITY FOR ANY LOSS OF DATA.&nbsp; WE ARE NOT RESPONSIBLE FOR ANY CHANGES IN YOUR AMAZON ACCOUNT
					STATUS OR SERVICES BASED ON ANY ACTION TAKEN BY YOU SUCH AS, FOR EXAMPLE, OPENING AN EXCESSIVE AMOUNT OF
					AMAZON SUPPORT CASES IN A SINGLE DAY, OR OPENING CASES THAT ARE NOT VALID.&nbsp; Valence Intelligence DOES NOT
					GUARANTEE THAT ANY DISCREPANCY FOUND BY THE SERVICE IS 100% ACCURATE.&nbsp; YOU ACKNOWLEDGE THAT ERRORS IN
					RESULTS CAN BE DERIVED FROM A VARIETY OF SOURCES INCLUDING AMAZON ACCESS LIMITATIONS AND LIMITATIONS IN
					AMAZON&rsquo;S INTERNAL TOOLS.&nbsp; YOU ARE RESPONSIBLE FOR VALIDATING ALL DISCREPANCIES UNCOVERED BY THE
					SERVICE PRIOR TO OPENING A CASE WITH AMAZON AND TO LIMIT THE NUMBER OF CASES YOU OPEN TO AVOID ANY NEGATIVE
					EFFECTS ON YOUR AMAZON ACCOUNT STANDING.&nbsp;
				</ListItem>

				<ListItem>
					<strong>Limitation of Liability. </strong>EXCEPT TO THE MINIMUM EXTENT REQUIRED BY APPLICABLE LAW AND THEN
					ONLY TO THAT EXTENT, IN NO EVENT WILL Valence Intelligence, ITS EMPLOYEES, OR SUPPLIERS BE LIABLE FOR ANY
					DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA OR PROFIT, OR DUE TO BUSINESS INTERRUPTION)
					ARISING OUT OF THE USE OR INABILITY TO USE THE MATERIALS ON Valence Intelligence&rsquo;S WEBSITE, EVEN IF
					Valence Intelligence OR A Valence Intelligence AUTHORIZED REPRESENTATIVE HAS BEEN NOTIFIED ORALLY OR IN
					WRITING OF THE POSSIBILITY OF SUCH DAMAGE.&nbsp; BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON
					IMPLIED WARRANTIED, OR LIMITATIONS OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES THESE LIMITATIONS MAY
					NOT APPLY TO YOU.&nbsp; OUR TOTAL AGGREGATE&nbsp; LIABILITY FOR ALL CLAIMS MADE ABOUT THE SERVICE WILL BE NO
					MORE THAN WHAT YOU PAID US IN THE SIX MONTHS PRIOR TO THE EVENT GIVING RISE TO THE APPLICABLE CLAIM.
				</ListItem>

				<ListItem>
					<strong>Results Not Guaranteed. </strong>Valence Intelligence makes no guarantee or representation of any kind
					concerning the results of your use of the Website or Service. Any testimonials or examples displayed or
					depicted through Valence Intelligence&rsquo;s Website, programs, and/or the Service are only examples of what
					may be possible.
				</ListItem>

				<ListItem>
					<strong>Indemnification for Breach of Terms of Use. </strong>You agree to indemnify and hold Valence
					Intelligence, its officers, directors, shareholders, predecessors, successors in interest, employees, agents,
					subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including
					attorneys' fees), made against Valence Intelligence by any third party due to or arising out of or in
					connection with your use of the Service or Website.
				</ListItem>

				<ListItem>
					<strong>Third Party Resources.</strong> The Website and the Service may contain links to third party websites
					or other resources. You acknowledge and agree that we are not responsible or liable for the availability,
					accuracy, content or policies of third party websites or other resources. Links to such websites or resources
					are provided only as a convenience to you.
				</ListItem>
			</OrderedList>
			<Heading my={3}>Security, Storage, &amp; Retention</Heading>
			<OrderedList>
				<ListItem>
					<strong>Security.</strong> The privacy and protection of your data is of the utmost importance to us. We take
					all reasonable technical and organizational precautions to protect the confidentiality, security and integrity
					of your Personal Data. Although we use multiple and various security measures to help protect your Personal
					Data against loss, misuse or unauthorized disclosure, we cannot 100% guarantee the security of information
					transmitted to us over the Internet.
				</ListItem>

				<ListItem>
					<strong>Storage.</strong> The Personal Data that you provide to us is generally stored on servers located in
					the United States. If you are located in another jurisdiction, you should be aware that once your Personal
					Data is submitted through our Service, it will be transferred to our servers in the United States and that the
					United States currently does not have uniform data protection laws in place.
				</ListItem>

				<ListItem>
					<strong>Retention.</strong> We will retain your information for as long as needed to provide you with our
					service. If you wish to cancel your account or request that we no longer use your information to provide you
					service, contact us at client_support@valenceintel.com. We will retain only that information necessary to
					comply with our legal obligations, resolve disputes, and enforce our agreements.
				</ListItem>
			</OrderedList>
			<Heading my={3}>Miscellaneous</Heading>
			<OrderedList>
				<ListItem>
					<strong>Privacy Policy.</strong> Valence Intelligence may use and disclose your information according to our
					Privacy Policy. Our Privacy Policy{' '}
					<span style={{ textDecoration: 'underline' }}>https://app.valenceintel.com/#/privacy-policy/</span> is
					incorporated into these Terms of Service.
				</ListItem>

				<ListItem>
					<strong>Intellectual Property</strong>. The Website, its original content, features, functionality (including
					look), our trademarks, service marks, logos, and Service content (&ldquo;Intellectual Property&rdquo;) are
					owned by Valence Intelligence and are protected by United States and international copyright, trademark,
					patent, trade secret and other intellectual property or proprietary rights laws. You agree to not copy,
					modify, create derivative works of, publicly display, publicly perform, or republish any of our Intellectual
					Property. The term &ldquo;content&rdquo; includes, without limitation, information, data, text, photographs,
					videos, audio clips, written posts and comments, software, scripts, graphics, AdWords, and interactive
					features generated, provided, or otherwise made accessible on the Website or otherwise through Valence
					Intelligence.
				</ListItem>

				<ListItem>
					<strong>Customer Data</strong>. "Customer Data" means information, data, and other content, in any form or
					medium, including your billing and other personal information, that is submitted, posted, or otherwise
					transmitted by you, or a third party on your behalf, through the Service. As between you and Valence
					Intelligence, you own all right, title, and interest, including all intellectual property rights, in and to
					the Customer Data. You hereby grant Valence Intelligence a non-exclusive, royalty-free, worldwide license to
					reproduce, distribute, and otherwise use and display the Customer Data and perform all acts with respect to
					the Customer Data as may be necessary for Valence Intelligence to provide the Service and a non-exclusive,
					perpetual, irrevocable, royalty-free, worldwide license to use the Customer Data for analytics purposes.
					Valence Intelligence will only use your billing and other personal information for the purpose of providing
					you the Service.
				</ListItem>

				<ListItem>
					<strong>International Users</strong>. The Service is controlled, operated and administered by Valence
					Intelligence from our offices within the USA and Canada. If you access the Service from a location outside the
					USA, you are responsible for compliance with all local laws. You agree that you will not use the content
					accessed through the Website in any country or in any manner prohibited by any applicable laws, restrictions
					or regulations.
				</ListItem>

				<ListItem>
					<strong>Compliance with Law.</strong> You represent and warrant that your use and interaction with Valence
					Intelligence and its Service is in compliance with all national, federal, state, and local laws, ordinances
					and regulations. If you are located in a country outside the United States it is your responsibility to
					determine that you are in compliance with the laws of that country. You agree to indemnify and hold us
					harmless from any losses, including attorney fees that result from your breach of any part of these
					warranties.
				</ListItem>

				<ListItem>
					<strong>Force Majeure.</strong> We shall not be liable for any failure of or delay in the performance of this
					agreement for the period that such failure or delay is due to causes beyond our reasonable control, including
					but not limited to acts of god, war, hackers, third party internet providers, government orders, power
					failures, pandemic, epidemic, labor strike, nuclear destruction, armageddon or any other force majeure event.
				</ListItem>

				<ListItem>
					<strong>Severability.</strong> The invalidity or unenforceability of any provisions of these Terms shall not
					affect the validity or enforceability of any other provisions of these Terms, which shall remain in full force
					and effect.
				</ListItem>

				<ListItem>
					<strong>Severability.</strong> Any provision of these Terms which imposes an obligation after termination or
					expiration of this agreement shall survive the termination or expiration of this agreement.
				</ListItem>

				<ListItem>
					<strong>Headings.</strong> The section headings contained in these Terms are for reference purposes only and
					shall not affect in any way the meaning or interpretation of these Terms.
				</ListItem>

				<ListItem>
					<strong>Waiver.</strong> Our failure to exercise or delay in exercising any right, power or privilege under
					these Terms shall not operate as a waiver; nor shall any single or partial exercise of any right, power or
					privilege preclude any other or further exercise thereof.
				</ListItem>

				<ListItem>
					<strong>Governing Law.</strong> This Agreement shall be governed, construed, and enforced in accordance with
					the laws of the State of Missouri without regard to its conflict of laws rules, or international law or
					convention.
				</ListItem>

				<ListItem>
					<strong>Dispute Resolution.</strong> Any dispute or controversy you or we have arising under or in connection
					with this agreement shall be settled exclusively by binding arbitration solely by written submission in Kansas
					City, in the State of Missouri, in accordance with the expedited Commercial rules of the American Arbitration
					Association then in effect, by one arbitrator. Judgment may be entered on the arbitrator&rsquo;s award in any
					court having jurisdiction. The arbitrator shall not have the power to award any punitive or consequential
					damages. Arbitration shall proceed solely on an individual basis without the right for any claims to be
					arbitrated on a class action basis or on bases involving claims brought in a purported representative capacity
					on behalf of others. The arbitrator&rsquo;s authority to resolve and make written awards is limited to claims
					between you and us alone. Claims may not be joined or consolidated unless agreed to in writing by all parties.
					No arbitration award or decision will have any preclusive effect as to issues or claims in any dispute with
					anyone who is not a named party to the arbitration.
				</ListItem>
			</OrderedList>
			<p>
				The above notwithstanding, if you violate these Terms then we may seek injunctive or other equitable relief.
			</p>
			<OrderedList>
				<ListItem>
					<strong>Assignment.</strong> You may not assign any of your rights under this agreement to anyone else. We may
					assign our rights at our discretion.
				</ListItem>

				<ListItem>
					<strong>Notice.</strong> All notices to you will be effective when we send it to the last email or physical
					address you gave us or posted on our Website. Any notice to us will be effective when delivered to us at:
				</ListItem>
			</OrderedList>
			<Box my={3}>
				<p>client_support@valenceintel.com</p>
				<p>Valence Intelligence</p>
				<p>136 S Main St #400</p>
				<p>Salt Lake City, UT 84101</p>
			</Box>
			<Box my={3}>
				<strong>Copyright Infringement. </strong>&nbsp;If copyrighted content that belongs to you was posted on the
				Website without your permission, please notify us at:
			</Box>
			<Box my={3}>
				<p>client_support@valenceintel.com</p>
				<p>Valence Intelligence</p>
				<p>136 S Main St #400</p>
				<p>Salt Lake City, UT 84101</p>
				<p>Please include in your notice:</p>
			</Box>
			<UnorderedList>
				<ListItem>
					An electronic or physical signature of the copyright owner or someone authorized to act on their behalf;
				</ListItem>
				<ListItem>The name, address, telephone number, and email address of the copyright owner;</ListItem>
				<ListItem>Identification of the copyrighted work that is being infringed;</ListItem>
				<ListItem>
					Identification of where the infringing material is located on our Website (a URL works best);
				</ListItem>
				<ListItem>
					A statement that you have a good faith belief that the use isn&rsquo;t authorized by the copyright owner, its
					agent or the law;
				</ListItem>
				<ListItem>A statement that the information in your notice is accurate, and</ListItem>
				<ListItem>A statement that you&rsquo;re authorized to act on behalf of the copyright owner.</ListItem>
				<ListItem>
					This statement must be made under penalty of perjury, meaning if any part of the statement is false, you could
					be committing perjury&mdash;a serious offense that&rsquo;s sometimes even classified as a felony.
				</ListItem>
			</UnorderedList>
			<p>
				<strong>Warning</strong>: If you knowingly make a false statement in your claim of copyright infringement, then
				you may be subject to liability for damages and heavy civil penalties. If you are not sure whether material on
				one of our Websites infringes your copyright, then you should speak with a lawyer before notifying us. We may
				forward your notice to the user that uploaded the content.
			</p>
			<OrderedList>
				<ListItem>
					<strong>Electronic Communications</strong>. When you use the Service, or send emails, text messages, and other
					communications from your desktop or mobile device to us, you are communicating with us electronically. You
					consent to receive communications from us. We will communicate with you in a variety of ways, such as by
					e-mail, text, or by posting notices and messages on this Website. You agree that all agreements, notices,
					disclosures, and other communications that we provide to you electronically satisfy any legal requirement that
					such communications be in writing.
				</ListItem>

				<ListItem>
					<strong>Entire Agreement. </strong>These terms, together with our Privacy Policy and any additional terms you
					have agreed to, constitute(s) the entire agreement with respect to its subject matter and constitutes and
					supersedes all prior agreements, representations and understandings of the parties, written or oral.
				</ListItem>
			</OrderedList>
		</Box>
	)
}
