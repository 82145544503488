import { createElement } from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useUser } from 'hooks/user/user'
import ErrorFallback from 'components/ErrorFallback'

type ProtectedRouteProps = {
	component: any
	tag: string
	exact?: boolean
	path: string
}

const ProtectedRoute = ({ component, tag, exact = false, path }: ProtectedRouteProps) => {
	const { user, isLoading } = useUser()
	return (
		<Route
			exact={exact}
			path={path}
			render={() => {
				if (isLoading) return <div />
				return user ? (
					<Sentry.ErrorBoundary
						fallback={({ error }) => <ErrorFallback error={error} />}
						beforeCapture={(scope) => scope.setTag('location', tag)}
					>
						{createElement(component)}
					</Sentry.ErrorBoundary>
				) : (
					<Redirect to='/login' />
				)
			}}
		/>
	)
}

export default ProtectedRoute
