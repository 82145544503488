import { Box, BoxProps } from '@chakra-ui/react'
interface CardProps extends BoxProps {
	children: React.ReactNode
}
const Card = (props: CardProps) => {
	return (
		<Box borderRadius='5px' boxShadow='base' bgColor='white' {...props}>
			{props.children}
		</Box>
	)
}

export default Card
