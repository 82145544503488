import { useQuery } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { fetchCognitoUser } from './userFetchers'
import toast from 'react-hot-toast'
import { fetcher } from 'api/fetchers'

export function useUser() {
	const { isLoading, error, data } = useQuery({
		queryKey: [`cognitoUser`],
		queryFn: fetchCognitoUser,
		retry: false,
	})

	useEffect(() => {
		if (!data?.attributes || !data?.username) return
		Sentry.setUser({ email: data.username })
		Sentry.setContext('UserAttributes', data.attributes)
	}, [data?.attributes, data?.username])

	return {
		user: data,
		isLoading,
		isError: error,
	}
}

export function useUserData() {
	const { isLoading, error, data } = useQuery({
		queryKey: [`/users/user`],
		queryFn: () => fetcher(`/users/user`),
	})

	if (error instanceof Error) {
		if ((error as Error).message !== 'accesstoken is undefined') {
			console.log(error)
			toast.error('Error fetching user data')
		}
	}

	return {
		data: data || {},
		isLoading,
		isError: error,
		permissions: data?.Permissions,
		currentRole: data?.Role,
	}
}
