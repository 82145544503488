import { useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { useForm, Controller } from 'react-hook-form'
import {
	MdOutlineAccountCircle as AccountCircle,
	MdOutlineEmail as Email,
	MdOutlineBusiness as Business,
} from 'react-icons/md'
import { Box, BoxProps, Checkbox, Flex } from '@chakra-ui/react'
import { useHistory, Link as RRLink, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import ReCaptcha from 'react-google-recaptcha'

import ReactGA from 'react-ga4'

import PrimaryButton from 'components/UIComponents/PrimaryButton'
import Link from 'components/UIComponents/Link'
import { validateEmail, validatePassword, validateRequired, checkPasswordMatch } from 'utils/formValidation'
import PasswordInput from 'components/PasswordInput'
import FormInput from 'components/FormInput'

import api from 'api'

type submission = {
	name: string
	email: string
	password: string
	confirmPassword: string
	company: string
	terms: boolean
	firstName?: string
	lastName?: string
}

function EmbeddableSignUp() {
	const { referrer } = useParams<any>()

	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const recaptchaRef = useRef<any>()

	useEffect(() => {
		const rootBox = document.getElementById('rootBox')
		const root = document.getElementById('root')
		if (rootBox && root) {
			rootBox.style.backgroundColor = 'rgba(0,0,0,0)'
			root.style.backgroundColor = 'rgba(0,0,0,0)'
			document.body.style.backgroundColor = 'rgba(0,0,0,0)'
		}
	}, [])

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
		reset,
	} = useForm<submission>()

	const onSubmit = async (formData: submission) => {
		try {
			if (await isBot()) {
				toast.error('Looks like you might be a robot. If not, please contact us at client_support@valenceintel.com.')
				// reset the state but keep the data
				reset(formData)
				return
			}

			setLoading(true)
			try {
				ReactGA.event({
					category: 'Signup Screen',
					action: 'Signup Button',
					label: 'Button',
				})
			} catch (e) {
				console.log(e)
			}

			await api.post('/signup', {
				...formData,
				referrer,
				referrerUrl: document.referrer,
			})
			try {
				//post message so sifted.com GA code can receive signup attribution
				parent.postMessage('mi_signup', 'https://sifted.com/')
			} catch (e) {
				console.log(e)
			}
			history.push(`/check_email?email=${formData.email}`)
		} catch (e: any) {
			toast.error(e?.response?.data?.message)
			setLoading(false)
		}
	}

	const validateCaptcha = async () => {
		const token = await recaptchaRef.current.executeAsync()
		await api.post('/signup/captcha', { captcha: token })
	}

	async function isBot() {
		try {
			await validateCaptcha()
		} catch (e: any) {
			toast.error(e.response.data.error.message)
			return true
		}

		return false
	}

	return (
		<Box display='flex' justifyContent='center' alignContent='center'>
			<Helmet>
				<title>Valence Intelligence | Embedded Signup</title>
			</Helmet>

			<Flex
				w={['100%', '100%', '50%']}
				h={['100%', '100%', '100vh']}
				direction='column'
				align='center'
				justify='center'
			>
				<Box
					as='form'
					onSubmit={handleSubmit(onSubmit)}
					display='flex'
					flexDir='column'
					justifyContent='space-between'
					alignItems='center'
				>
					<Box {...inputBoxStyles}>
						<FormInput
							name='name'
							control={control}
							rules={{
								validate: {
									checkRequired: (v: string) => validateRequired(v),
								},
							}}
							label='Full Name'
							helperText={errors?.name?.message}
							error={errors?.name ? true : false}
							Icon={<AccountCircle fontSize='large' />}
						/>
					</Box>

					<Box {...inputBoxStyles}>
						<FormInput
							name='email'
							control={control}
							rules={{
								validate: {
									checkEmail: (v: string) => validateEmail(v),
								},
							}}
							label='Email'
							helperText={errors?.email?.message}
							error={errors?.email ? true : false}
							Icon={<Email fontSize='large' />}
						/>
					</Box>
					<Box {...inputBoxStyles}>
						<PasswordInput
							name='password'
							control={control}
							rules={{
								validate: {
									checkPassword: (v: string) => validatePassword(v),
								},
							}}
							helperText={
								errors?.confirmPassword?.message ? (
									errors?.confirmPassword?.message
								) : (
									<Flex direction='column' align='center'>
										<span>Passwords must contain:</span>
										<ul>
											<li>8 characters</li>
											<li>A uppercase letter</li>
											<li>A lowercase letter,</li>
											<li>A special character</li>
											<li>A number</li>
										</ul>
									</Flex>
								)
							}
							error={errors?.password ? true : false}
							label='Password'
						/>
					</Box>
					<Box {...inputBoxStyles}>
						<PasswordInput
							name='confirmPassword'
							control={control}
							rules={{
								validate: {
									checkPassword: (v: string) => checkPasswordMatch(v, watch('password')),
								},
							}}
							error={errors?.confirmPassword ? true : false}
							helperText={errors?.confirmPassword?.message}
							label='Confirm Password'
						/>
					</Box>
					<Box {...inputBoxStyles}>
						<FormInput
							name='company'
							control={control}
							rules={{
								validate: {
									checkRequired: (v: string) => validateRequired(v),
								},
							}}
							label='Company'
							helperText={errors?.company?.message}
							error={errors?.company ? true : false}
							Icon={<Business fontSize='large' />}
						/>
					</Box>
					<Box {...inputBoxStyles} fontSize='sm'>
						<Flex align='center' padding={1}>
							<Controller
								name='terms'
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<Checkbox
										isChecked={field.value}
										onChange={field.onChange}
										pr='5px'
										colorScheme='primary'
										bgColor='white'
									/>
								)}
							/>
							I agree to all{' '}
							<Link as={RRLink} to='/terms_and_conditions' target='_blank' rel='noreferrer' p='3px'>
								Terms
							</Link>
							and{' '}
							<Link href='https://sifted.com/privacy-policy/' target='_blank' rel='noreferrer' p='3px'>
								Privacy Policy
							</Link>
						</Flex>

						{errors.terms && (
							<Box color='red.500' fontSize='sm'>
								Required
							</Box>
						)}
					</Box>

					<ReCaptcha ref={recaptchaRef} sitekey={import.meta.env.VITE_CAPTCHA_PUBLIC_SITE_KEY} size='invisible' />

					<Flex align='center' justify='center'>
						<PrimaryButton type='submit' isDisabled={loading} isLoading={loading}>
							Create Account
						</PrimaryButton>
					</Flex>
				</Box>
			</Flex>
		</Box>
	)
}

export default EmbeddableSignUp

const inputBoxStyles: BoxProps = {
	w: '100%',
	m: '10px 0px',
}
