import { extendTheme } from '@chakra-ui/react'
const theme = extendTheme({
	colors: {
		vOrange: {
			'50': '#FEF4E8',
			'100': '#FDE9D1',
			'200': '#FCD4A3',
			'300': '#FABF76',
			'400': '#F9AA4A',
			'500': '#F7941D',
			'600': '#C67818',
			'700': '#945A12',
			'800': '#633C0C',
			'900': '#311E06',
		},
		vBlue: {
			'50': '#E4F4FB',
			'100': '#CAE9F7',
			'200': '#B0DFF3',
			'300': '#52B7E2',
			'400': '#3CAEDE',
			'500': '#149BD7',
			'600': '#0F7DAB',
			'700': '#0C5D81',
			'800': '#083E56',
			'900': '#041F2B',
		},
	},
	fonts: {
		heading: `'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif`,
		body: `'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif`,
	},
})

export { theme }
