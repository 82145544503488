import { useHistory } from 'react-router-dom'
import { Box, Center, Flex, Icon, Text } from '@chakra-ui/react'
import api from 'api'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import SecondaryButton from 'components/UIComponents/SecondaryButton'
import { useQueryParams } from 'hooks'
import { useEffect, useState } from 'react'
import { BiError } from 'react-icons/bi'
import Swal from 'sweetalert2'

function InvalidConfirmationCode() {
	const query = useQueryParams()
	const [loading, setLoading] = useState(false)
	const [timer, setTimer] = useState(30)
	const history = useHistory()

	useEffect(() => {
		if (!loading) return
		const interval = setInterval(() => {
			if (timer > 0) setTimer(timer - 1)
		}, 1000)
		if (loading) {
			if (timer === 0) {
				setLoading(false)
				setTimer(30)
			}
		}
		return () => clearInterval(interval)
	}, [loading, timer])

	async function resendConfirmation() {
		setLoading(true)
		try {
			await api.post('/signup/resend_confirmation', {
				email: query.get('email'),
			})
			void Swal.fire({
				icon: 'success',
				title: 'Sent!',
				text: `Confirmation email resent to ${query.get('email')}.  Please be sure to check your spam folder as well`,
			})
		} catch (e: any) {
			void Swal.fire({
				icon: 'error',
				title: 'Error!',
				text: e?.response?.data,
			})
			setLoading(false)
		}
	}

	function renderBody() {
		if (!query.get('email')) {
			return (
				<>
					<Text align='center'>
						Looks like something went wrong while we were trying to authenticate your email. Please contact
						client_support@valenceintel.com
					</Text>
					<Box display='flex' justifyContent='center'>
						<SecondaryButton
							onClick={() => {
								history.push('/')
							}}
						>
							Home
						</SecondaryButton>
					</Box>
				</>
			)
		}

		return (
			<>
				<Text align='center'>
					<Text align='center'>
						Looks like that was an expired or incorrect confirmation link. Let's get you a new one
					</Text>

					<Box marginTop={5}>
						<PrimaryButton onClick={resendConfirmation} isDisabled={loading} marginRight={3}>
							{loading ? `Can resend in ${timer}` : 'Resend Email'}
						</PrimaryButton>
						<SecondaryButton
							onClick={() => {
								history.push('/')
							}}
						>
							Home
						</SecondaryButton>
					</Box>
				</Text>
				<Text align='center'>If you are still having issues please contact client_support@valenceintel.com</Text>
			</>
		)
	}

	return (
		<Flex width='100%' height='100vh' justify='center' align='center'>
			<Flex
				maxW='md'
				minW='sm'
				minH='md'
				borderWidth='1px'
				borderRadius='lg'
				bg='white'
				direction='column'
				padding='40px'
				justify='space-between'
			>
				<Center>
					<Icon as={BiError} color='vBlue.600' fontSize='7xl' />
				</Center>
				<Text fontWeight='bold' align='center' fontSize='3xl'>
					Invalid Link
				</Text>

				{renderBody()}
			</Flex>
		</Flex>
	)
}

export default InvalidConfirmationCode
