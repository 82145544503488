import { useHistory } from 'react-router-dom'
import PrimaryButton from 'components/UIComponents/PrimaryButton'

import styles from './Completed.module.scss'

function Completed() {
	const history = useHistory()

	return (
		<div className={styles.notAuthorized}>
			<div className={styles.card}>
				<div className={styles.notAuthorizedHeader}>Survey Completed!</div>
				<div className={styles.notAuthorizedText}>
					Thank you for completing the survey! If you have any questions please contact us at{' '}
					<a href='mailto:client_support@valenceintel.com'>client_support@valenceintel.com</a>
				</div>
				<div className={styles.homeButtonContainer}>
					<PrimaryButton onClick={() => history.push('/')}>Home</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default Completed
