import { Controller, Control } from 'react-hook-form'

import {
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	FormLabel,
	FormControl,
	InputProps,
} from '@chakra-ui/react'

type FormInputProps = {
	name: string
	defaultValue?: string
	label?: string
	rules?: any
	control: Control<any>
	Icon?: React.ReactNode
	helperText: any
	error: boolean
	type?: string
	placeholder?: string
	inputProps?: InputProps
}

const FormInput = ({
	name,
	defaultValue = '',
	label,
	type = 'text',
	rules,
	Icon,
	control,
	error,
	helperText,
	placeholder,
	inputProps,
}: FormInputProps) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field }) => (
				<FormControl isInvalid={error}>
					<FormLabel>{label}</FormLabel>
					{Icon && (
						<InputGroup>
							<InputLeftElement color={'gray.400'}>{Icon}</InputLeftElement>
							<Input {...field} type={type} fontSize='14px' placeholder={placeholder} {...inputProps} />
						</InputGroup>
					)}
					{!Icon && <Input {...field} type={type} {...inputProps} />}
					<FormErrorMessage>{helperText}</FormErrorMessage>
				</FormControl>
			)}
		/>
	)
}

export default FormInput
