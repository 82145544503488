import { lazy, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'components/ProtectedRoute'
import Loader from 'components/Loader'

const LazyDashboard = lazy(() => import('views/Dashboard'))
const LazyTopbar = lazy(() => import('views/Topbar'))
const LazyAudits = lazy(() => import('views/FBAAudit'))
const LazySalesVisualization = lazy(() => import('views/SalesVisualization'))
const LazySettings = lazy(() => import('views/Settings'))
const LazyFeeAnalysis = lazy(() => import('views/FeeAnalysis'))
const LazyLearn = lazy(() => import('views/Learn'))
const LazySellerAuthRedirect = lazy(() => import('views/SellerAuthRedirect'))
const LazyPageNotFound = lazy(() => import('views/PageNotFound'))

const ProtectedRoutes = () => {
	return (
		<>
			<Suspense fallback={<Loader />}>
				<LazyTopbar />
			</Suspense>
			<Suspense fallback={<Loader />}>
				<Switch>
					<ProtectedRoute component={LazyDashboard} tag='Dashboard' exact path='/' />
					<ProtectedRoute component={LazyAudits} tag='FBA Audit' exact path='/fba_audit/:tab?' />
					<ProtectedRoute
						component={LazySalesVisualization}
						tag='Sales Visualization'
						exact
						path='/sales_visualization'
					/>
					<ProtectedRoute component={LazySettings} tag='Settings' exact path='/settings/:tab?' />
					<ProtectedRoute component={LazyFeeAnalysis} tag='Fee Analysis' exact path='/fee_analysis/:tab?' />
					<ProtectedRoute component={LazyLearn} tag='Help' exact path='/learn' />
					<ProtectedRoute
						component={LazySellerAuthRedirect}
						tag='Auth Redirect'
						exact
						path='/seller_authentication_redirect'
					/>
					<ProtectedRoute component={LazyPageNotFound} tag='Page Not Found' exact path='*' />
				</Switch>
			</Suspense>
		</>
	)
}

export default ProtectedRoutes
