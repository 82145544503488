import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import api from 'api'

export async function fetchCognitoUser() {
	const user = await getCurrentUser()
	const attributes = await fetchUserAttributes()
	if (user) {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {}
		api.defaults.headers.common['accesstoken'] = accessToken?.toString()
		return { ...user, attributes }
	} else {
		throw new Error('User not found')
	}
}
