import {
	Input,
	FormControl,
	FormErrorMessage,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	Box,
	BoxProps,
} from '@chakra-ui/react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import _ from 'lodash'

import api from 'api'

import PrimaryButton from 'components/UIComponents/PrimaryButton'
import InfoTooltip from 'components/InfoTooltip'
import SelectorButtonGroup from './SelectorButtonGroup'

import { isNullOrUndefined } from 'utils'
import { validateEmail } from 'utils/formValidation'

type defaultFormValues = {
	defaultIsManufacture: any
	defaultAutoBill: any
	defaultAccessToBoLs: any
	defaultInboundAuditDollarMinimum: any
	defaultDocumentationContactEmail: any
	defaultBillingContactEmail: any
	defaultHandlingInboundAudits: any
}

type QuestionnaireFormProps = {
	cognitoSub: string | undefined
	sellingPartnerId: string | undefined
	defaults: defaultFormValues
	onSubmit: (data: any) => void
	notifyUserOnComplete?: boolean
}

function QuestionnaireForm({
	cognitoSub,
	sellingPartnerId,
	defaults,
	onSubmit,
	notifyUserOnComplete = true,
}: QuestionnaireFormProps) {
	const [handlingInboundAudits, setHandlingInboundAudits] = useState<boolean | undefined>(
		defaults.defaultHandlingInboundAudits
	)
	const [isManufacturer, setIsManufacturer] = useState<boolean | undefined>(
		_.isNil(defaults.defaultIsManufacture) ? undefined : !!defaults.defaultIsManufacture
	)
	const [hasBOLs, setHasBOLs] = useState<boolean | undefined>(
		_.isNil(defaults.defaultAccessToBoLs) ? undefined : !!defaults.defaultAccessToBoLs
	)
	const [notifyEveryShipment, setNotifyEveryShipment] = useState<boolean | undefined>(
		_.isNil(defaults.defaultInboundAuditDollarMinimum) ? undefined : !!defaults.defaultInboundAuditDollarMinimum
	)
	const [dollarLimit, setDollarLimit] = useState(
		_.isNil(defaults.defaultInboundAuditDollarMinimum) ? 0 : parseFloat(defaults.defaultInboundAuditDollarMinimum)
	)
	const [documentationContact, setDocumentationContact] = useState(
		_.isNil(defaults.defaultDocumentationContactEmail) ? '' : defaults.defaultDocumentationContactEmail
	)
	const [billingContact, setBillingContact] = useState(
		_.isNil(defaults.defaultBillingContactEmail) ? '' : defaults.defaultBillingContactEmail
	)
	const [autoBill, setAutoBill] = useState<boolean | undefined>(
		_.isNil(defaults.defaultAutoBill) ? undefined : !!defaults.defaultAutoBill
	)
	const [billingEmailError, setBillingEmailError] = useState(false)
	const [documentationEmailError, setDocumentationEmailError] = useState(false)

	const sendData = async (updateObject: any) => {
		await api.put('/questionnaire', {
			cognitoSub,
			sellingPartnerId,
			...updateObject,
		})
	}

	return (
		<>
			<Box {...optionsContainerStyles}>
				<Box {...inlineTooltipStyles}>
					Use the card on file for invoices?{' '}
					<InfoTooltip text='We charge the card on file on the 5th of each month for successful reimbursements from the previous month. You can see a detailed report on what our team submitted in the billing tab.' />
					<Box as='span' {...subtextStyles}>
						(Recommended)
					</Box>
				</Box>
				{!autoBill && (
					<Box as='span' {...subtextStyles}>
						By selecting "NO" you will have 30 days to pay an invoice, and then we will auto charge the card on file
					</Box>
				)}
				<SelectorButtonGroup
					isTrueSelection={autoBill}
					setStateCallback={(value: any) => {
						setAutoBill(value)
						void sendData({ autoBill: value ? 1 : 0 })
					}}
				/>
			</Box>

			<Box {...optionsContainerStyles}>
				<Box {...inlineTooltipStyles}>
					Do you want us to handle inbound shipment reconciliation?{' '}
					<InfoTooltip text='Inbound shipment discrepancies require a setup of a shipment plan so we can gather the proper documentation for submission. This will help maximize your reimbursement success.' />
				</Box>
				<SelectorButtonGroup
					isTrueSelection={handlingInboundAudits}
					setStateCallback={(value: any) => {
						setHandlingInboundAudits(value)
						void sendData({ handlingInboundAudits: value ? 1 : 0 })
					}}
				/>
			</Box>

			{handlingInboundAudits && (
				<Box {...optionsContainerStyles}>
					<Box {...inlineTooltipStyles}>
						Are you the registered brand owner or manufacturer of your product?{' '}
						<InfoTooltip text='If you are the registered brand owner of your products in Amazon, or the manufacture, we can use packing slips when creating inbound shipment cases.' />
					</Box>
					<SelectorButtonGroup
						isTrueSelection={isManufacturer}
						setStateCallback={(value: any) => {
							setIsManufacturer(value)
							void sendData({ manufacturer: value ? 1 : 0 })
						}}
					/>
				</Box>
			)}

			{handlingInboundAudits && (
				<Box {...optionsContainerStyles}>
					<Box {...inlineTooltipStyles}>
						Do you have access to stamped/signed Bill of Ladings or invoices?
						<InfoTooltip text="If you are unclear how to access these, don't worry! Our team can help create a plan for how we work together to access these. Our goal is a hands off process where you are free to focus on running your business." />
					</Box>
					<SelectorButtonGroup
						isTrueSelection={hasBOLs}
						setStateCallback={(value: any) => {
							setHasBOLs(value)
							void sendData({ BOLs: value ? 1 : 0 })
						}}
					/>
				</Box>
			)}

			{handlingInboundAudits && (
				<Box {...optionsContainerStyles}>
					<Box {...inlineTooltipStyles}>
						Should we notify you for every shipment? Or just shipments over a dollar limit?
						<InfoTooltip text='We recognize that some shipments might seem too small or not worth the time for some sellers. We recommend going after all reimbursements if possible.' />
					</Box>
					<SelectorButtonGroup
						isTrueSelection={notifyEveryShipment}
						setStateCallback={(value: any) => {
							setNotifyEveryShipment(value)
							void sendData({
								notificationDollarMinimum: value ? 0 : dollarLimit,
							})
						}}
						yesLabel={'All'}
						noLabel={'$ Limit'}
					/>
					{notifyEveryShipment === false && (
						<Box width='80%' mt='40px' minH='30px' minW='200px'>
							<Slider
								aria-label='Dollar Limit Slider'
								defaultValue={0}
								value={dollarLimit || 0}
								onChange={(newValue) => {
									setDollarLimit(newValue)
								}}
								onBlur={() => {
									void sendData({
										notificationDollarMinimum: notifyEveryShipment ? 0 : dollarLimit || 0,
									})
								}}
								min={0}
								max={10000}
							>
								<SliderMark
									value={dollarLimit}
									textAlign='center'
									bg='blue.500'
									borderRadius='5px'
									color='white'
									mt='-10'
									ml='-5'
									w='100px'
								>
									{new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'usd',
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
									}).format(dollarLimit)}
								</SliderMark>
								<SliderTrack>
									<SliderFilledTrack />
								</SliderTrack>
								<SliderThumb />
							</Slider>
						</Box>
					)}
				</Box>
			)}

			{handlingInboundAudits && (
				<Box {...optionsContainerStyles}>
					<div> Who is the documentation contact?</div>
					<Box width='80%' pt='15px'>
						<FormControl isInvalid={documentationEmailError}>
							<Input
								value={documentationContact}
								placeholder='Email'
								onChange={(event) => setDocumentationContact(event.target.value)}
								onBlur={(event) => {
									if (validateEmail(event.target.value) !== 'Invalid Email' && event.target.value !== '') {
										void sendData({
											documentationContactEmail: event.target.value,
										})
										setDocumentationEmailError(false)
									} else {
										setDocumentationEmailError(true)
									}
								}}
							/>
							<FormErrorMessage>{documentationEmailError ? 'Invalid Email' : ''}</FormErrorMessage>
						</FormControl>
					</Box>
				</Box>
			)}

			{handlingInboundAudits && (
				<Box {...optionsContainerStyles}>
					<div> Who is the billing contact?</div>
					<Box width='80%' pt='15px'>
						<FormControl isInvalid={billingEmailError}>
							<Input
								value={billingContact}
								placeholder='Email'
								onChange={(event) => setBillingContact(event.target.value)}
								onBlur={(event) => {
									if (validateEmail(event.target.value) !== 'Invalid Email' && event.target.value !== '') {
										void sendData({
											billingContactEmail: event.target.value,
										})
										setBillingEmailError(false)
									} else {
										setBillingEmailError(true)
									}
								}}
							/>
							<FormErrorMessage>{billingEmailError ? 'Invalid Email' : ''}</FormErrorMessage>
						</FormControl>
					</Box>
				</Box>
			)}

			{!isNullOrUndefined(handlingInboundAudits) && (
				<Box pt='30px'>
					<PrimaryButton
						onClick={() => {
							void sendData({ surveyCompleted: 1 })
							onSubmit({
								surveyCompleted: 1,
								isManufacturer,
								hasBOLs,
								notifyEveryShipment,
								dollarLimit,
								documentationContact,
								billingContact,
								autoBill,
							})
							if (notifyUserOnComplete) {
								void Swal.fire({
									icon: 'success',
									title: 'Completed!',
									text: 'Thank you for completing the questionnaire!',
								})
							}
						}}
					>
						Submit
					</PrimaryButton>
				</Box>
			)}
		</>
	)
}

const optionsContainerStyles: BoxProps = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: '40px',
	width: '100%',
}

const inlineTooltipStyles: BoxProps = {
	display: 'flex',
	alignItems: 'center',
}

const subtextStyles: BoxProps = {
	fontSize: '14px',
	fontStyle: 'italic',
	fontWeight: 'bold',
}

export default QuestionnaireForm
