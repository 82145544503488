import { Tooltip, PlacementWithLogical } from '@chakra-ui/react'
import { MdInfoOutline as InfoOutline } from 'react-icons/md'

import styles from './InfoTooltip.module.scss'

type InfoTooltipProps = {
	text: string | JSX.Element
	placement?: PlacementWithLogical
}
const InfoTooltip = ({ text, placement }: InfoTooltipProps) => {
	return (
		<Tooltip label={text} placement={placement}>
			<span className={styles.tooltipIcon}>
				<InfoOutline />
			</span>
		</Tooltip>
	)
}
export default InfoTooltip
