import {
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	Image,
	Input,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react'
import Card from 'components/UIComponents/Card'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import Logo from 'images/ValenceLogoLight.png'
import { useState } from 'react'
import { carriers } from './carriers'
import CarrierCredentialsForm from './CarrierCredentialsForm'
import { useForm } from 'react-hook-form'

function CarrierCredentials() {
	const [selectedCarriers, setSelectedCarriers] = useState<string[]>([])
	const [finishedSelection, setFinishedSelection] = useState<boolean>(false)
	const [companyName, setCompanyName] = useState('')
	const [email, setEmail] = useState('')

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		setError,
	} = useForm({
		defaultValues: {
			companyName: companyName,
			email: email,
			selectedCarriers: selectedCarriers,
		},
	})

	const onSubmit = (data: any) => {
		if (data.selectedCarriers.length === 0) {
			setError('selectedCarriers', {
				type: 'manual',
				message: 'Please select at least one carrier.',
			})
			return
		}

		setFinishedSelection(true)
		setSelectedCarriers(data.selectedCarriers)
		setCompanyName(data.companyName)
		setEmail(data.email)
		reset()
	}

	function renderMultiSetForm() {
		if (!finishedSelection) {
			return (
				<form onSubmit={handleSubmit(onSubmit)}>
					<VStack gap={4}>
						<FormControl isInvalid={!!errors.companyName}>
							<Input
								placeholder='Company Name'
								{...register('companyName', { required: 'Company Name is required' })}
							/>
							<FormErrorMessage>{errors.companyName && errors.companyName.message}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.email}>
							<Input placeholder='Email' type='email' {...register('email', { required: 'Email is required' })} />
							<FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
						</FormControl>
						<Text>Please Select the carriers that you use</Text>
						<Divider width='100%' />
						<FormControl isInvalid={!!errors.selectedCarriers}>
							<CheckboxGroup>
								<Stack spacing={2}>
									{Object.keys(carriers)
										.sort(compareStringsForSort)
										.map((carrierKey) => (
											<Checkbox key={carrierKey} {...register('selectedCarriers')} value={carrierKey}>
												{carriers[carrierKey].name}
											</Checkbox>
										))}
								</Stack>
								<FormErrorMessage>{errors.selectedCarriers && errors.selectedCarriers.message}</FormErrorMessage>
							</CheckboxGroup>
						</FormControl>
						<Flex width='100%' justify='flex-end'>
							<PrimaryButton type='submit'>Next</PrimaryButton>
						</Flex>
					</VStack>
				</form>
			)
		}

		return <CarrierCredentialsForm carriers={selectedCarriers} companyName={companyName} email={email} />
	}

	return (
		<Flex
			align='center'
			bgImage={`url(https://mihostedimages.s3.amazonaws.com/Login_bg.png)`}
			bgSize='cover'
			bgPosition='center'
			w='100%'
			h='100vh'
			flexDirection='column'
		>
			<Card mt={10} p={5} maxWidth='450px'>
				<VStack>
					<Image src={Logo} alt='logo' mb='23px' height='80px' />
					{renderMultiSetForm()}
					<Divider width='100%' mt={3} />
					<Text mt={5} fontSize='sm' textAlign='center'>
						Your data belongs to you. Valence does not share your information, and will only use it with your
						permission.
					</Text>
					<Text mt={5} fontSize='sm' textAlign='center'>
						If you would like to be covered under an NDA, it can be downloaded{' '}
						<Link href='https://mihostedimages.s3.amazonaws.com/Mutual+NDA.pdf' textDecor='underline' isExternal>
							here
						</Link>
						. Simply sign it and email the completed NDA to{' '}
						<Link href='mailto:client_support@valenceintel.com'>client_support@valenceintel.com.</Link>
					</Text>
				</VStack>
			</Card>
		</Flex>
	)
}

function compareStringsForSort(a: string, b: string) {
	if (a === 'Other') {
		return 1
	}
	if (b === 'Other') {
		return -1
	}
	return a > b ? 1 : -1
}

export default CarrierCredentials
